import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { globalSettingSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { globalSetting_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Select from 'react-select'
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
const vatTypeList = [{
  label: "FIXED",
  value: "FIXED",
}, {
  label: "PERCENTAGE",
  value: "PERCENTAGE",
}
];

function GlobalSettingList() {
  const pages = [{ title: "Global Setting List", href: `/global-setting`, module_id: 38 }];
  const [loading, setLoading] = useState(true);
  const [globalSettingList, setGlobalSettingList] = useState(false)
  const [countryList, setCountryList] = useState(false)
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({ page_size: 5, page_no: 1 });

  const [gs_id, setGlobalSettingId] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    gs_id: null,
    gs_is_active: false,
  });

  const HospList = [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Country List", countryList);
  }, [countryList])

  const getAllCountries = async () => {
    MasterServices.getCountryList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setCountryList(data.data);
          } else {
            toast.error("getCountryList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getCountryList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };
  useEffect(() => {
    document.title = "Labib | GlobalSettings";
    getAllGlobalSettingList(1);
    getAllCountries();
  }, [globalFilter]);


  const getAllGlobalSettingList = (page) => {
    let payload = {
      search: 3,
      search_text: globalFilter ? globalFilter : "",
      page_size: pagination?.page_size ? pagination.page_size : 5,
      page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
    };
    MasterServices.getAllGlobalSettings(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setGlobalSettingList(data.data);
            setPagination(data.meta);
          } else {
            toast.error("GlobalSettingList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllGlobalSettingList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  useEffect(() => {
    console.log("Settings=>")

  });
  let initialValues = {
    gs_id: "",
    gs_vat: "",
    gs_vat_number: "",
    gs_vatType: "",
    fk_country_id: "",
  };

  const [formGlobalSetting, setFormGlobalSetting] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setGlobalSettingId(id);
      const initialValues = {
        gs_id: obj.gs_id,
        gs_vat: obj.gs_vat,
        gs_vat_number: obj.gs_vat_number,
        gs_vatType: obj.gs_vatType,
        fk_country_id: obj.fk_country_id,
      };
      setFormGlobalSetting(initialValues);
    } else {
      setGlobalSettingId("")
      setFormGlobalSetting(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (gs_id, gs_is_active) => {
    setConfirmationModal({ gs_id, gs_is_active, status: true });
  };

  const onDeleteGlobalSetting = (gs_id, gs_is_active) => {
    let Newgs_is_active = false;
    Newgs_is_active = gs_is_active == true ? false : true;
    let text = "Activated";
    if (Newgs_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setGlobalSettingStatus({
      gs_id,
      gs_is_active: Newgs_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Global Setting " + text + " Successfully!")
          setConfirmationModal({ gs_id: null, status: false });
          getAllGlobalSettingList(1);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ gs_id: null, status: false });
  };

  const handlePageChange = (page) => {
    getAllGlobalSettingList(page);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({

      enableReinitialize: true,
      initialValues: formGlobalSetting,
      validationSchema: globalSettingSchema,
      onSubmit: async (values, action) => {
        console.log("values", values);

        let body = {
          gs_id: gs_id,
          gs_vat: values.gs_vat,
          gs_vat_number: values.gs_vat_number,
          gs_vatType: values.gs_vatType,
          fk_country_id: parseInt(values.fk_country_id),
        }
        MasterServices.saveGlobalSetting(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (gs_id != 0 || gs_id != '') {
                toast.success("Global Setting Updated Successfully");
              } else {
                toast.success("Global Setting Added Successfully");
              }
              getAllGlobalSettingList(1);
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = "saveGlobalSetting Catch Error" //error.response.data.message;
            toast.error(msg)
          });
        action.resetForm();
        if (modalOpenFlage === true) {
          setmodalOpenFlage(false);
          navigate(`/global-setting`);
        };
        console.log("globalSettingList", globalSettingList);
      }
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.gs_is_active ? "Disable " : "Activate") + " this Global Setting?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteGlobalSetting(confirmationModal.gs_id, confirmationModal.gs_is_active)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">GlobalSetting List</h1>

      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>

      {
        loading ? (
          <FallingLinesLoader />
        ) : ((globalSettingList.length > 0) ? (
          <Table
            columns={globalSetting_columns({ onDeleteOpen, handleDrawer })}
            data={globalSettingList}
            pagination={pagination}
            onPageChange={handlePageChange}
            onGlobalFilterChange={setGlobalFilter}
          />
        ) : <TableEmpty />)
      }


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">

                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {gs_id ? "Update" : "Add"} Global Setting
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                {countryList.length > 0 ? (
                                  <div className="sm:col-span-2 sm:mt-1">
                                    <div>
                                      <label
                                        htmlFor="project-name"
                                        className="block text-sm font-medium text-gray-900 mb-1"
                                      >
                                        Country
                                      </label>
                                    </div>
                                    <select
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="fk_country_id"
                                      id="fk_country_id"
                                      value={values.fk_country_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value={null}> Select Country </option>
                                      {countryList?.map((country, i) => (
                                        <option
                                          defaultValue={gs_id ? values.fk_country_id : ""
                                          }
                                          key={country.country_id}
                                          value={country.country_id}
                                        >
                                          {country.country_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (<></>)}

                                {errors.fk_country_id && touched.fk_country_id ? (
                                  <div className="text-sm text-red-600">
                                    {errors.fk_country_id}
                                  </div>
                                ) : null}
                              </div>

                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Vat Type
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  {vatTypeList.length > 0 ? (
                                    <select
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="gs_vatType"
                                      id="gs_vatType"
                                      value={values.gs_vatType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value={null}> Select Vat Type </option>
                                      {vatTypeList?.map((vatlist, i) => (
                                        <option
                                          defaultValue={gs_id ? values.gs_vatType : ""
                                          }
                                          key={i}
                                          value={vatlist.value}
                                        >
                                          {vatlist.label}
                                        </option>
                                      ))}
                                    </select>) : (<></>)}
                                  {errors.gs_vatType && touched.gs_vatType ? (
                                    <p className="text-red-600 text-sm">{errors.gs_vatType}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Vat Number
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.gs_vat_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Vat Number"
                                    name="gs_vat_number"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.gs_vat_number && touched.gs_vat_number ? (
                                    <p className="text-red-600 text-sm">{errors.gs_vat_number}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="sm:col-span-2 sm:mt-1">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Vat Value
                                  </label>
                                </div>
                                <div className="sm:col-span-2 sm:mt-1">
                                  <input
                                    value={values.gs_vat}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Vat Value"
                                    name="gs_vat"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.gs_vat && touched.gs_vat ? (
                                    <p className="text-red-600 text-sm">{errors.gs_vat}</p>
                                  ) : null}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false)
                            }
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {gs_id ? 'Update' : 'Add'}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default GlobalSettingList;