import React, { useState, Fragment, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { methodology } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import Modal from "../../../components/Modal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomButton from "../../../components/common/CustomButton";
import ExcelServices from "../../../ApiServices/ExcelServices";

const initialModalStateReservationsUsed = {
  state: false,
  data: [],
};

const Methodology = () => {
  const pages = [
    { title: "Methodology List", href: "/methodology", module_id: 25 },
  ];
  const [loading, setLoading] = useState(false);
  const [methodologyList, setMethodologyList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({ page_size: 10, page_no: 1 });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    methodology_id: null,
  });
  const navigate = useNavigate();

  const downloadSample = async () => {
    try {
      const response = await ExcelServices.getMethodologySampleExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MethodologySampleTemplate.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const exportMethodologies = async () => {
    try {
      const response = await ExcelServices.downloadMethodologiesExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LabAnalyzerExport.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  async function getMethodologyList(page) {
    // setLoading(true);
    let payload = {
      search: "All",
      search_text: globalFilter ? globalFilter : "",
      page_size: pagination?.page_size ? pagination.page_size : 10,
      page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
    };
    try {
      const { data, status } = await MasterServices.getMethodology(payload);
      if (status === 200) {
        setPagination(data.meta);
        setMethodologyList(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getMethodologyList(pagination.page_no);
  }, [globalFilter]);

  const handlePageChange = (page) => {
    getMethodologyList(page);
  };

  async function deleteMethodology(payload) {
    setLoading(true);
    try {
      const { data, status } = await MasterServices.deleteMethodology(payload);
      setConfirmationModal({ methodology_id: null, status: false });
      if (status === 200) {
        await getMethodologyList(1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  const onDeleteOpen = (methodology_id) => {
    setConfirmationModal({ methodology_id, status: true });
  };

  useEffect(() => {
    getMethodologyList(1);
  }, []);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deleteMethodology(confirmationModal.methodology_id)}
        />
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">
            Methodology List
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Methodologies.
          </p>
        </div>
        <div className="mt-4 flex items-center justify-end gap-3">
          {/* <Link
                to="/add-methodology"
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            >
                Add
          </Link> */}

          <CustomButton onClick={() => navigate("/add-methodology")}>
            Add
          </CustomButton>

          <CustomButton onClick={() => exportMethodologies()}>Export</CustomButton>
          <CustomButton onClick={() => { }}>Import</CustomButton>
          <CustomButton onClick={() => downloadSample()}>
            Download Sample
          </CustomButton>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : methodologyList.length > 0 ? (
          <Table
            columns={methodology({ onDeleteOpen })}
            data={methodologyList}
            pagination={pagination}
            onPageChange={handlePageChange}
            onGlobalFilterChange={setGlobalFilter}
          />
        ) : (
          <TableEmpty />
        )}
      </div>
    </>
  );
};

export default Methodology;
