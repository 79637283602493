/* eslint-disable import/no-anonymous-default-export */
import Api from './Api';
import { BASEURL } from "../helper/constant.js";
import { getParams } from '../helper/commonHelper.js';

export default {
	// Sample Section Start
	async addSampleComment(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/comment`, payload);
		return response;
	},

	async saveUnitName(payload){
		const response = await Api().post(`api/dashboard/tests/sample/save-unit`, payload);
		return response;
	},

	async getAllSampleList(payload) {
		let param = getParams(payload)
		const response = await Api().get(`api/dashboard/tests/sample/list/${payload.id}?${param}`);
		return response;
	},
	async getAllSampleListFilterList(payload) {
		const response = await Api().get(`api/dashboard/tests/sample/list/filter/all/?${payload}`);
		return response;
	},

	async setSampleStatus(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/status`, payload);
		return response;
	},

	async getAllSampleAttachmentList(payload) {
		console.log(payload)
		const response = await Api().get(`api/dashboard/tests/sample/attachment/attachmentlist/${payload}`);
		return response;
	},

	async addAttachmentToSample(payload) {
		let progress = 0;
		let response = await Api().post(`api/dashboard/tests/sample/attach_file`, payload,{
			headers: {
			  "Content-Type": "multipart/form-data",
			},
			onUploadProgress: data => {
			  //Set the progress value to show the progress bar
			  progress = (Math.round((100 * data.loaded) / data.total));
			},
		  });
		  response.data.progress=progress;
		return response;
	},

	async updateAttachmentToSample(payload) {
		// http://localhost:8004/api/dashboard/tests/sample/list/0
		const response = await Api().post(`api/dashboard/tests/sample/update-file`, payload);
		return response;
	},
	// Sample Section Start

	async getSampleDetails(payload){
		const response = await Api().get(`api/dashboard/tests/sample/details/${payload}`);
        return response;
	},
	async getSampleLogDetails(payload){
		const response = await Api().get(`api/dashboard/tests/sample/samplelog/list/log/${payload}`);
        return response;
	},
	
	async setSampleLocation(sample_id, payload){
		const response = await Api().post(`api/dashboard/tests/sample/loc/${sample_id}`, payload);
        return response;
	},

	async getAllSampleListForProcessing(payload) {
		const response = await Api().get(`api/dashboard/tests/sample/processing/list/${payload}`);
		return response;
	},
	async getAllSampleListForProcessingPagination(payload) {
		const response = await Api().get(`api/dashboard/tests/sample/processing/list/filter/all/pagination/${payload.page}/${payload.pageSize}`);
		return response;
	},
	async getAllResSamplesForProcessing(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/res-processing/list`,payload);
		return response;
	},
	async getAllSampleListForProcessingFilterList(payload) {
		console.log("getAllSampleListFilterList payload=>",payload)
		const response = await Api().get(`api/dashboard/tests/sample/processing/list/filter/all/?${payload}`);
		return response;
	},
	async saveSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/result/`, payload);
		return response;
	},
	async saveSampleTestParamUnitResult(payload) {
		const response = await Api().post(`api/dashboard/result/save-unit`, payload);
		return response;
	},
	async validateResult(payload) {
		const response = await Api().post(`api/dashboard/result/validate/`,payload);
		return response;
	},
	async approveResult(payload) {
		const response = await Api().post(`api/dashboard/result/approve/`,payload);
		return response;
	},
	async validateResultBak(payload) {
		const response = await Api().post(`api/dashboard/result/validate/${payload}`);
		return response;
	},

	async validateAndApproveResult(payload) {
		const response = await Api().post(`api/dashboard/result/validate-and-approve`, payload);
		return response;
	},

	async approveResultBak(payload) {
		const response = await Api().post(`api/dashboard/result/approve/${payload}`);
		return response;
	},
	async updateSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/result/update`, payload);
		return response;
	},
	async positiveNegativeSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/result/positive-negative`, payload);
		return response;
	},
	async colorSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/result/color-result`, payload);
		return response;
	},
	async customSelectSampleTestResult(payload) {
		const response = await Api().post(`api/dashboard/result/custom-select-result`, payload);
		return response;
	},
	async generateReport(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/generate_report`,payload);
		return response;
	},
	async getAllReservationSamplesResult(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/res_sample_param_deatils`,payload);
		return response;
	},

	async changeRefRangeOfSample(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/change-ref-range`, payload);
		return response;
	},
	// Result Comment Start
	async updateSampleTestResultComment(payload){
		const response = await Api().post(`api/dashboard/result/comment`, payload);
		return response;
	},
	// Result Comment End

	async receiveSampleAtLab(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/received/${payload}`)
		return response;
	},
	async recollectSampleAtLab(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/recollect/${payload}`)
		return response;
	},
	async rejectSampleByLab(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/rejected/${payload}`)
		return response;
	},
	async receiveSampleAtLabAll(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/received-all`,payload)
		return response;
	},
	async rejectSampleByLabAll(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/rejected-all`,payload)
		return response;
	},
	async getAllSamplesForReceiving(){
		const response = await Api().get(`api/dashboard/tests/sample/receiving/all-samples`)
		return response
	},
	async updateSampleTestResultLabAnalygerLoc(payload){
		const response = await Api().post(`api/dashboard/result/lab-analyger-loc`, payload);
		return response;	
	},
	async updateProcessingLocationForMultipleSamples(payload){
		const response = await Api().post(`api/dashboard/tests/sample/status/multiple-samples-loc-assign`,payload)
		return response;
	},

	async sendReportLinkSMS(payload) {
		const response = await Api().post(
		  `api/dashboard/tests/sample/sms/report`,
		  payload
		);
		return response;
	},
};
