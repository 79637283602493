import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import Api from '../../../ApiServices/Api';
import { appointment_columns, reservation_columns } from '../../../components/tables/tableheader';
import { reservation_status_colors } from '../reservation/reservationStatusColorCodes';

const AppointmentList = () => {
    const pages = [
        { title: "Appointments", href: "/appointments", module_id: null },
      ];
      const [isLoading, setIsLoading] = useState(true);
      const [appointments, setAppointments] = useState([])

      useEffect(() => {
        document.title = "Labib | Appointments";
        getAllAppointments();
      }, []);
    
      const getAllAppointments = async () => {
        try {
          setIsLoading(true);
          const { data } = await Api().get("/api/dashboard/appointments/get-res");
          if (data.status) {
            setAppointments(data.data);
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
        <Breadcrumb pages={pages} />
        <div className="">
            <h1 className="text-xl font-semibold text-gray-900">Appointments</h1>
            <p className="mt-2 text-sm text-gray-700">
                A list of all the Appointments
            </p>
        </div>
        {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={appointment_columns({reservation_status_colors})}
          data={appointments?.length > 0 && !isLoading ? appointments : []}
        />
      )}
    </div>
  )
}

export default AppointmentList