import React, { Fragment, useRef, Component, createRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { patient_member_list_columns } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import MasterServices from "../../../ApiServices/MasterServices";
import ReservationServices from '../../../ApiServices/ReservationServices';
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toast";
import EditPatient from "../reservation/EditPatient";

function PatientMemberList() {

	const { user_id: user_id } = useParams();
	const pages = [{ title: "Patient's Member List", href: "/all-member-list/", module_id: 2 }];
	const [isLoading, setIsLoading] = useState(true);
	const [isPatient, setIsPatient] = useState(true);
	const [userPatientList, setPatientUserList] = useState([]);
	const [parentUser, setParentUser] = useState({});
	const [openPatient, setOpenPatient] = useState(false);
	const [showPatientData, setShowPatientData] = useState({});

	const [openEditPatient, setOpenEditPatient] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [filteredCountryList, setFilteredCountryList] = useState(true);
	const [selectedPatient, setSelectedPatient] = useState(false)

	const cancelButtonRef = useRef(null);

	useEffect(() => {
		document.title = "Labib | Patients Member";
		setIsLoading(true)
		getAllPatientUserList(user_id);
		setIsLoading(false)
		getCountryList()
	}, []);

	useEffect(() => {
		let tempArr = []
		if (countryList.length > 0) {
			let indexOfSA = countryList.findIndex(country => {
				return country.country_id == 203
			});
			tempArr = array_move(countryList, indexOfSA, 0)
			setFilteredCountryList(tempArr)
		}
	}, countryList)

	function array_move(arr, old_index, new_index) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr; // for testing
	};

	const getAllPatientUserList = (user_id) => {
		let meta={
			currentPage: 1,
			page:1,
			totalPages: 1,
			pageSize: 500,
			search_text:'',
		}
		ReservationServices.getSingleUserBasedPatient(user_id,meta)
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					if (data?.data?.length > 0) {
						setPatientUserList(data.data);
						const selfObject = data?.data.find(obj => obj.relationship === 'Self');
						setIsPatient(false);
						setParentUser(selfObject);
					} else {
						toast.error("PatientList Not Found")
					}
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
			})
			.catch((error) => {
				const msg = "getAllPatientUserList catch Error";
				toast.error(msg);
			});
	}

	const getCountryList = () => {
		MasterServices.getCountryList()
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					if (data?.data?.length > 0) {
						setCountryList(data.data);
						let tempArr = []
						if (data.data.length > 0) {
							let indexOfSA = data.data.findIndex(country => {
								return country.country_id == 203
							});
							tempArr = array_move(data.data, indexOfSA, 0)
							setFilteredCountryList(tempArr)
						}
					}
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
			})
			.catch((error) => {
				const msg = "getCountryList catch Error"; //error.response.data.message;
				toast.error(msg);
			});
	}

	const showDetails = (obj) => {
		console.log("showDetails", obj);
		setShowPatientData(obj);
		setOpenPatient(true);
	}

	const showEditPatient = (obj) =>{
		setShowPatientData(obj);
		setSelectedPatient(obj.patient_id)
		setOpenEditPatient(true)
	}

	const handleCloseEditPatient = () =>{
		setOpenEditPatient(false)
	}

	const addUserPatient = (body, action) => {
		ReservationServices.saveUserPatient(body)
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					toast.success(data.message);
					getAllPatientUserList(user_id)
					//   setOpenPatient(false);
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
			})
			.catch((error) => {
				const msg = "saveUserPatient Catch Error" //error.response.data.message;
				toast.error(msg)
			});
		action.resetForm();
	};

	const closeModal = () => {
		setOpenPatient(false);
		setSelectedPatient(false)
	}

	return (
		isLoading ? <FallingLinesLoader /> : (
			<div className="px-4 sm:px-6 lg:px-8">

				<Breadcrumb pages={pages} />
				<div className="">
					{/* <h1 className="text-xl font-semibold text-gray-900">Member List of {parentUser?.patient_first_name + ' ' + parentUser?.patient_last_name}</h1> */}

					<h1 className="text-xl font-semibold text-gray-900">Member List
						{parentUser.patient_first_name || parentUser.patient_last_name
							? " of " + parentUser?.patient_first_name || "" + ' ' + parentUser?.patient_last_name || "" // For Proper Spacing
							: ''}
					</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all the Patients.</p>
				</div>

				{isPatient ? (
					<FallingLinesLoader />
				) : (
					<Table
						columns={patient_member_list_columns({ showDetails , showEditPatient})}
						data={userPatientList}
					/>
				)}
				<Transition.Root show={openPatient} as={Fragment}>
					<Dialog as="div" className="relative z-30" onClose={() => { setOpenPatient(false); closeModal() }}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
										<div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
											<div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
												<div className="space-y-6 sm:space-y-5">
													<div className="grid grid-cols-2 gap-x-16 gap-y-4">
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Full Name</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_first_name}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2 ">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Relationship</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.relationship}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Nationality</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData?.m_country_relation?.country_name}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Identity Type</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_id_type}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Passport No.</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_passport_no}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Mobile No.</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_mobile}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_email}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Gender</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData?.m_gender_relation?.gender_name_en}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Date of Birth</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_dob}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2 gap-4">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Reason of testing</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_reasion_test}</dd>
															</div>
														</div>
														<div className="grid grid-cols-2 gap-4">
															<div>
																<dt className="text-sm font-medium leading-6 text-gray-900">Referance MRN#</dt>
															</div>
															<div>
																<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{showPatientData.patient_ref_mrn_no}</dd>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				<EditPatient
					cancelButtonRef={cancelButtonRef}
					patient_id={selectedPatient}
					userType={showPatientData.relationship == "Self" ? "User" : "Patient"}
					openPatient={openEditPatient}
					setSelectedPatient={setSelectedPatient}
					setOpenPatient={setOpenPatient}
					closeModal={handleCloseEditPatient}
					addUserPatient={addUserPatient}
					user_id={showPatientData.fk_user_id}
					filteredCountryList={filteredCountryList}
				/>
			</div>)

	);
}
export default PatientMemberList;