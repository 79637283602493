import React, { useEffect, useState, Fragment } from 'react'
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition, } from "@headlessui/react";
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { Link } from 'react-router-dom';
import { sample_type_columns } from '../../../components/tables/tableheader';
import { useFormik } from 'formik';
import { sampleTypeSchema } from '../../../schemas';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import ConfirmationModal from '../../../components/ConfirmationModal';

const SampleType = () => {
    const pages = [{ title: "Sample Type List", href: "/sample-type" }];

    const initialValues = {
        sample_type_id: "",
        sample_type_name_en: "",
        sample_type_name_ar: "",
        sample_type_color: "",
    };
    const [loading, setLoading] = useState(true)
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [sampleTypeList, setSampleTypeList] = useState(false)
    const [testColorList, setTestColorList] = useState([]);
    const [formState, setFormState] = useState(initialValues);
    const [sampleTypeId, setSampleTypeId] = useState('')
    const [globalFilter, setGlobalFilter] = useState("");
    const [pagination, setPagination] = useState({ page_size: 25, page_no: 1 });
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        sample_type_id: null,
        st_is_active: false,
    });

    useEffect(() => {
        document.title = "Labib | Sample Type List";
        getAllSampleTypeList(1)
        getTestColorList();
    }, [])

    const handleDrawer = (type, id, obj) => {
        if (type === "edit", id) {
            setSampleTypeId(id)
            const initialValues = {
                sample_type_id: obj.sample_type_id,
                sample_type_name_en: obj.sample_type_name_en,
                sample_type_name_ar: obj.sample_type_name_ar,
                sample_type_color: obj.sample_type_color
            };
            setFormState(initialValues);
        } else {
            setSampleTypeId("")
            setFormState(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
        // setmodalOpenFlage(true);
    };
    const getAllSampleTypeList = (page) => {
        let payload = {
            search: "All",
            search_text: globalFilter ? globalFilter : "",
            page_size: pagination?.page_size ? pagination.page_size : 25,
            page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
          };
        MasterServices.getSampleTypeList(payload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setPagination(data.meta);
                        setSampleTypeList(data?.data);
                    } else {
                        toast.error("Sample Type Not Found")
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setLoading(false);
            })
            .catch((error) => {
                const msg = "getAllSampleTypeList catch Error"; //error.response.data.message;
                toast.error(msg);
                setLoading(false);
            });
    }

    useEffect(() => {
        getAllSampleTypeList(pagination.page_no);
      }, [globalFilter]);
    
      const handlePageChange = (page) => {
        getAllSampleTypeList(page);
      };

    const getTestColorList = async () => {
        MasterServices.getTestColorList()
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setTestColorList(data.data);
                    } else {
                        toast.error("Test Color List Not Found")
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = "Error in Test Color";
                toast.error(msg)
            });

    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formState,
            validationSchema: sampleTypeSchema,
            onSubmit: (values, action) => {
                console.log("values", values)
                MasterServices.saveSampleType(values)
                    .then((response) => {
                        const { data, status } = response;
                        if (status == 200) {
                            if (sampleTypeId != 0 || sampleTypeId != '') {
                                toast.success("Sample Type Updated Successfully");
                            } else {
                                toast.success("Sample Type Added Successfully");
                            }
                            getAllSampleTypeList();
                        } else {
                            toast.error("Fatal Error Please Contact Admin")
                        }
                    })
                    .catch((error) => {
                        const msg = "getAllSampleTypeList Catch Error" //error.response.data.message;
                        toast.error(msg)
                    });
                action.resetForm();
                if (modalOpenFlage === true) {

                    setmodalOpenFlage(false);
                };
            },

        });
    const onDeleteOpen = (sample_type_id, st_is_active) => {
        setConfirmationModal({ sample_type_id, st_is_active, status: true });
        console.log(sample_type_id, "sampleTypeId")
    };
    const onDeleteSampleType = (sample_type_id, st_is_active) => {
        let check_is_active = false;
        check_is_active = st_is_active == true ? false : true;
        let text = "Activated";
        if (check_is_active == true) {
            text = "Activated";
        } else {
            text = "In-Activated";
        }
        MasterServices.setSampleTypeStatus({ sample_type_id, st_is_active: check_is_active })
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("Sample Type " + text + " Successfully!")
                    setConfirmationModal({ sample_type_id: null, status: false });
                    getAllSampleTypeList();
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = "getAllSampleTypeList Catch Error"; //error.response.data.message;
                toast.error(msg)
            });

    };

    return (
        <div>
            <ConfirmationModal
                title={confirmationModal.st_is_active ? "Are you sure you want to Disable? " : "Are you sure you want to Activate?"}
                confirmationButtonText="Yes"
                // description={confirmationModal.price_is_active ? "Do you really want to In-Activate? " : "Do you really want to Activate?"}
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteSampleType(confirmationModal.sample_type_id, confirmationModal.st_is_active)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Sample Type List</h1>
                <p className="mt-2 text-sm text-gray-700">
                    A list of all the Sample Type List.
                </p>
            </div>
            <div className="mt-4 flex">
                <Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add Sample Type
                </Link>
            </div>

            {
                loading ? (
                    <FallingLinesLoader />
                ) : (sampleTypeList.length > 0) ? (
                    <Table
                        columns={sample_type_columns({ handleDrawer, onDeleteOpen })}
                        data={sampleTypeList}
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onGlobalFilterChange={setGlobalFilter}
                    />
                ) : null
            }
            <div>
                <Transition.Root show={modalOpenFlage} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-30"
                        onClose={setmodalOpenFlage}
                    >
                        <div className="fixed inset-0" />
                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                                            <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                                <div className="h-0 flex-1 overflow-y-auto">
                                                    <div className="bg-cyan-700 py-6 p-4 sm:p-6">
                                                        <div className="flex items-center justify-between">
                                                            <Dialog.Title className="text-lg font-medium text-white">
                                                                {sampleTypeId ? "Update" : "Add"}  Sample Type
                                                            </Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                                                    onClick={() => setmodalOpenFlage(false)}
                                                                >
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XMarkIcon
                                                                        className="h-6 w-6"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-between">
                                                        <div className="divide-y divide-gray-200 p-4 sm:p-6">
                                                            <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="sample_type"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Enter Sample Type Name (EN)
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        value={values.sample_type_name_en}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="text"
                                                                        placeholder="Enter Sample Type Name"
                                                                        name="sample_type_name_en"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                    />
                                                                    {errors.sample_type_name_en && touched.sample_type_name_en ? (
                                                                        <p className="text-red-600 text-sm">{errors.sample_type_name_en}</p>
                                                                    ) : null}
                                                                </div>
                                                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="sample_type_name_ar"
                                                                            className="block text-sm font-medium text-gray-900 mb-1"
                                                                        >
                                                                            Enter Sample Type Name (AR)
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.sample_type_name_ar}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Sample Type Name"
                                                                            name="sample_type_name_ar"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.sample_type_name_ar && touched.sample_type_name_ar ? (
                                                                            <p className="text-red-600 text-sm">{errors.sample_type_name_ar}</p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="sample_type_color" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Test Color</label>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        {testColorList.length > 0 ? (
                                                                            <select
                                                                                value={values.sample_type_color}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                                name="sample_type_color"
                                                                                id="sample_type_color"
                                                                            >
                                                                                <option >Select Test Color</option>
                                                                                {testColorList.map((testColorData, i) => (
                                                                                    <option style={{ backgroundColor: testColorData.mclr_hex_code }} key={testColorData.mclr_name} value={testColorData.mclr_id}>
                                                                                        {testColorData.mclr_name} <span >{testColorData.mclr_hex_code}</span>
                                                                                    </option>
                                                                                ))}
                                                                            </select>) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                    <button
                                                        type="button"
                                                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        onClick={() => {
                                                            setmodalOpenFlage(false)
                                                        }
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                    >
                                                        {sampleTypeId ? 'Update' : 'Add'}
                                                    </button>
                                                </div>
                                            </form>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </div>
    )
}

export default SampleType