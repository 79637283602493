/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-toast";
import { supabase } from "../../supabase";
import {
	GET_ALL_ROLE_BEGIN,
	GET_ALL_ROLE_SUCCESS,
	GET_ALL_ROLE_ERROR,
	ADD_ROLE_BEGIN,
	ADD_ROLE_SUCCESS,
	ADD_ROLE_ERROR,
	GET_ROLE_BEGIN,
	GET_ROLE_SUCCESS,
	GET_ROLE_ERROR,
	UPDATE_ROLE_BEGIN,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_ERROR,
	DELETE_ROLE_BEGIN,
	DELETE_ROLE_SUCCESS,
	DELETE_ROLE_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";
import { getParams } from "../../helper/commonHelper";

const API = process.env.REACT_APP_API_URL;

export const getAllRoles = (payload) => async (dispatch) => {
	
	try {
		dispatch({
			type: GET_ALL_ROLE_BEGIN,
		});

		let param = getParams(payload)
		const { data } = await Api().get(`/api/dashboard/masters/role?${param}`);
		let updatedRoles = data?.data

		updatedRoles?.forEach((element, index) => {
			element.label = element.role_name_en
			element.value = element.role_id
			element.serial_no = index + 1
		});
		
		dispatch({
			type: GET_ALL_ROLE_SUCCESS,
			data: updatedRoles ? updatedRoles : [],
			pagination: data?.meta,
		});
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		console.log(error);
		dispatch({
			type: GET_ALL_ROLE_ERROR,
			error: msg,
		});
	}
};

export const addRole = (body) => async (dispatch) => {
	try {
		dispatch({
			type: ADD_ROLE_BEGIN,
		});
		const role_status = true;
		const res = await Api().post(
			`/api/dashboard/masters/role/save`,
			body
		);

		console.log(res);
		toast.success("Role added successfully!");

		// dispatch({
		// 	type: ADD_ROLE_SUCCESS,
		// 	data: data[0],
		// });
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: ADD_ROLE_ERROR,
			error: msg,
		});
	}
};

export const getRoleWithId = (role_id) => async (dispatch) => {
	try {
		dispatch({
			type: GET_ROLE_BEGIN,
		});

		const { data } = await supabase
			.from("m_role")
			.select()
			.eq("role_id", parseInt(role_id))
			.maybeSingle();

		console.log("GET_ROLE_BEGIN : ", data);
		dispatch({
			type: GET_ROLE_SUCCESS,
			data: data,
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: GET_ROLE_ERROR,
			error: msg,
		});
	}
};

export const updateRole = (role_id, body) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_ROLE_BEGIN,
		});

		const res = await Api().post(
			`/api/dashboard/masters/role/save`,
			body
		);

		console.log(res);
		toast.success("Role updated successfully!");
		dispatch({
			type: UPDATE_ROLE_SUCCESS,
			// data: data[0],
		});
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: UPDATE_ROLE_ERROR,
			error: msg,
		});
	}
};

export const deleteRole = (role_id) => async (dispatch) => {
	try {
		dispatch({
			type: DELETE_ROLE_BEGIN,
		});
		const role_status = 0;
		const body = {
			role_id: role_id,
		};
		console.log(body, "deleteBody");
		const res = await Api().post(
			`/api/dashboard/masters/role/delete`,
			body
		);

		if (res.status) {
			toast.success("Role deleted successfully!");
			dispatch({
				type: DELETE_ROLE_SUCCESS,
				data: role_id,
			});
		}
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: DELETE_ROLE_ERROR,
			error: msg,
		});
	}
};
