import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { subtestSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import { getAllTests } from "../../../redux/test/actions";
import { toast } from 'react-toast'
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";
import { EditorState } from 'draft-js';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MasterServices from '../../../ApiServices/MasterServices'
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { Editor } from '@tinymce/tinymce-react';
import moment from "moment";
import makeAnimated from 'react-select/animated';
import Select from "react-select";


function className(...location) {
  return location.filter(Boolean).join(' ')
}
const AddTestParameter = () => {
  const { test_id: test_id } = useParams();
  const pages = [
    { title: "Add Test-Parameters", href: `/add-subtest/${test_id}` },
  ];
  const [referanceList, setReferanceList] = useState(false);
  const animatedComponents = makeAnimated();
  const [enProgress, setenProgress] = useState();
  const [imgEnfileName, setEnFile] = useState(false);
  const [imgEnfileNamePath, setEnFilePath] = useState('');
  const [arProgress, setarProgress] = useState();
  const [imgArfileName, setArFile] = useState(false);
  const [imgArfileNamePath, setArFilePath] = useState('');
  const [selectedReferanceRange, setSelectedReferanceRange] = useState('');
  const didYouKnowENEditorRef = useRef(null);
  const didYouKnowAREditorRef = useRef(null);
  const smartReportCommentENEditorRef = useRef(null);
  const smartReportCommentAREditorRef = useRef(null);
  const tipsENEditorRef = useRef(null);
  const tipsAREditorRef = useRef(null);

  let initialValues = {
    ttp_id: "",
    ttp_name_ar: "",
    ttp_name_en: "",
    ttp_img_en: "",
    ttp_img_ar: "",

    ttp_min_price: "",
    ttp_test_code: "",
    ttp_smart_report_description_en: "",
    ttp_smart_report_description_ar: "",

    ttp_short_description_en: "",
    ttp_short_description_ar: "",
    ttp_tips_description_en: "",
    ttp_tips_description_ar: "",
    fk_rr_id: "",
    fk_test_id: test_id,
    ttp_hos_price: "",
  };
  const handleEnFileChange = async (e) => {
    const response = await singleUploadFiles(e.target.files[0], 'sub_tests');
    setEnFilePath(response.data);
    setenProgress(response.progress);
    // setEnFile(e.target.files[0]);
  };
  const handleArFileChange = async (e) => {
    const response = await singleUploadFiles(e.target.files[0], 'sub_tests');
    setEnFilePath(response.data)
    setarProgress(response.progress);
    // setArFile(e.target.files[0]);
  };
  const [testParamsStatus, setTestParamsStatus] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false)
  const { ttp_id: ttp_id } = useParams();
  const [subTestList, setSubTestList] = useState(false)
  const {
    loading,
    test: { list: testList },

  } = useSelector((state) => state);
  useEffect(() => {
    document.title = "Labib | Test-Parameters";
    dispatch(getAllTests());
    getReferenceRangeList();
  }, []);
  useEffect(() => {
  });
  const [smartReportDescriptionEn, setSmartReportDescriptionEn] = useState(
    () => EditorState.createEmpty(),
  );

  const [formState, setFormState] = useState(initialValues);
  const [testData, settestData] = useState({});
  const getSubTestList = async () => {
    MasterServices.getSubTestList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Test-Parameter Found");
            setSubTestList(data.data);
          } else {
            toast.error("Test-Parameter Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });

  }

  const onClickHandler = () => {
    console.log(didYouKnowENEditorRef.current.getContent())
    console.log(didYouKnowAREditorRef.current.getContent())
    console.log(smartReportCommentENEditorRef.current.getContent())
    console.log(smartReportCommentAREditorRef.current.getContent())
    console.log(tipsENEditorRef.current.getContent())
    console.log(tipsAREditorRef.current.getContent())
  }

  const getReferenceRangeList = async () => {
    MasterServices.getAllReferenceRange()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setReferanceList(data.data);
          } else {
            toast.error("Reference Ranges Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg)
      });

  }


  //preview smart report
  const onPreviewClick = () => {
    generateSmartReportPrevPdf();

  }

  const generateSmartReportPrevPdf = async () => {
    // setIsLoading(true)
    try {
      let srData = {
        test_name_en: values.ttp_name_en,
        test_name_ar: values.ttp_name_ar,
        test_short_description_en: didYouKnowENEditorRef.current.getContent(),
        test_short_description_ar: didYouKnowAREditorRef.current.getContent(),
        test_smart_report_description_en: smartReportCommentENEditorRef.current.getContent(),
        test_smart_report_description_ar: smartReportCommentAREditorRef.current.getContent(),
        test_tips_description_en: tipsENEditorRef.current.getContent(),
        test_tips_description_ar: tipsAREditorRef.current.getContent(),
      };
      let payload = {
        pdf_name: 'Smart_Report_Preview_' + moment().format("DD-MM-YYYY"),
        data: srData
      }
      const { data, status } = await MasterServices.generateSmartReportPrevPDF(payload);
      console.log(data)
      if (status === 200) {
        if (data?.data?.pdf_url != '') {
          // window.location.replace(data.data.pdf_url);
          window.open(data.data.pdf_url, '__blank');
        }
        // setResUserReportData(data.data)
      }
      // setIsLoading(false)
    } catch (error) {
      console.log(error)
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    if (ttp_id) {
      MasterServices.getSingleSubTest(ttp_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            settestData(data.data);
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }
  }, [ttp_id]);

  useEffect(() => {
    console.log("Happy=>", testData.ttp_name_ar)
    document.title = "Labib | Add Test-Parameter";
    if (ttp_id && testData) {
      const newFormState = {
        ttp_name_ar: testData.ttp_name_ar,
        ttp_name_en: testData.ttp_name_en,
        ttp_img_en: testData.ttp_img_en,
        ttp_img_ar: testData.ttp_img_ar,

        ttp_min_price: testData.ttp_min_price,
        ttp_test_code: testData.ttp_test_code,
        ttp_smart_report_description_en: testData.ttp_smart_report_description_en,
        ttp_smart_report_description_ar: testData.ttp_smart_report_description_ar,
        ttp_short_description_en: testData.ttp_short_description_en,
        ttp_short_description_ar: testData.ttp_short_description_ar,
        ttp_tips_description_en: testData.ttp_tips_description_en,
        ttp_tips_description_ar: testData.ttp_tips_description_ar,
        fk_rr_id: testData.fk_rr_id != null ? testData.fk_rr_id : '',
        fk_test_id: testData.fk_test_id,
        ttp_hos_price: testData.ttp_hos_price,
      };
      setTestParamsStatus(testData.is_sub_test)
      setEnFilePath(testData.ttp_img_en ? testData.ttp_img_en : '');
      setArFilePath(testData.ttp_img_ar ? testData.ttp_img_ar : '');
      setFormState(newFormState);
      if (Array.isArray(referanceList) && referanceList.length > 0) {
        let rr = referanceList.filter(v => v.mrr_id == newFormState.fk_rr_id)
        console.log("rr---------------", rr, referanceList)
        if (rr.length > 0) {
          setSelectedReferanceRange({ value: rr[0].mrr_id, label: rr[0].mrr_name_en })
        }
      }
    }
  }, [ttp_id, testData, referanceList]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: subtestSchema,
      onSubmit: async (values, action) => {
        const body = { ...values };
        body.is_sub_test = testParamsStatus;
        body.ttp_short_description_en = didYouKnowENEditorRef?.current ? didYouKnowENEditorRef?.current?.getContent() : "N/A";
        body.ttp_short_description_ar = didYouKnowAREditorRef?.current ? didYouKnowAREditorRef?.current?.getContent() : "N/A";
        body.ttp_smart_report_description_en = smartReportCommentENEditorRef?.current ? smartReportCommentENEditorRef?.current?.getContent() : "N/A";
        body.ttp_smart_report_description_ar = smartReportCommentAREditorRef?.current ? smartReportCommentAREditorRef?.current.getContent() : "N/A";
        body.ttp_tips_description_en = tipsENEditorRef?.current ? tipsENEditorRef?.current?.getContent() : "N/A";
        body.ttp_tips_description_ar = tipsAREditorRef?.current ? tipsAREditorRef?.current?.getContent() : "N/A";
        // console.log(body);
        // return

        let fileResEn
        let fileResAr
        if (imgEnfileName) {
          fileResEn = await singleUploadFiles(imgEnfileName, 'sub_tests');
        }
        if (imgArfileName) {
          fileResAr = await singleUploadFiles(imgArfileName, 'sub_tests');
        }
        body.ttp_img_en = fileResEn ? fileResEn : imgEnfileNamePath;
        body.ttp_img_ar = fileResAr ? fileResAr : imgArfileNamePath;
        // body.ttp_smart_report_description_en=smartReportDescriptionEn;
        if (ttp_id != 0) {
          body.ttp_id = ttp_id;
        }
        MasterServices.saveSubTest(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (ttp_id > 0) {
                toast.success("Test-Parameter Updated Successfully");
              } else {
                toast.success("Test-Parameter Inserted Successfully");
              }
              navigate(`/test-tab/${test_id}`);
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = error.response.data.message;
            toast.error(msg)
          });
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {test_id && !ttp_id ? <div> <Breadcrumb pages={pages} />
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back
        </button>
      </div> : null}
      <div className="space-y-6 sm:space-y-5  mt-5 mb-5 px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {ttp_id ? "Update" : "Add"}
            </h1>
          </div>
        </div>
        <hr />
        {loading ? (
          <FallingLinesLoader />
        ) : (
          // onSubmit={handleSubmit}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="space-y-6 divide-gray-200"
          >
            <div className="flex flex-row mt-5 gap-5">
              <div className="basis-3/4">
                <label htmlFor="fk_test_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    disabled
                    value={values.fk_test_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    name="fk_test_id"
                    id="fk_test_id"
                  >
                    <option >Select Test</option>
                    {testList.map((tests, i) => (
                      <option selected={tests.test_id === values.fk_test_id ? "selected" : ""} key={i} value={tests.test_id}>
                        {tests.test_name_en}
                      </option>
                    ))}
                  </select>
                  {errors.fk_test_id && touched.fk_test_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_test_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="ttp_name_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test-Parameter Name(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test-Parameter Name(EN)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="ttp_name_en"
                    id="ttp_name_en"
                    value={values.ttp_name_en}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.ttp_name_en && touched.ttp_name_en ? (
                    <p className="text-red-600 text-sm">{errors.ttp_name_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="ttp_name_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Test-Parameter Name(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test-Parameter Name(AR)"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="ttp_name_ar"
                    id="ttp_name_ar"
                    value={values.ttp_name_ar}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.ttp_name_ar && touched.ttp_name_ar ? (
                    <p className="text-red-600 text-sm">{errors.ttp_name_ar}</p>
                  ) : null}
                </div>
              </div>
            </div>
            {/* <div className="flex flex-row mt-5 gap-5"> */}
            {/* <div className="basis-3/4">
                <label htmlFor="ttp_short_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Did you know(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      didYouKnowENEditorRef.current = editor
                    }}
                    onEditorChange={e => values.ttp_short_description_en = e}

                    initialValue={values.ttp_short_description_en}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_short_description_en && touched.ttp_short_description_en ? (
                    <p className="text-red-600 text-sm">{errors.ttp_short_description_en}</p>
                  ) : null}
                </div>
              </div> */}

            {/* <div className="basis-3/4">
                <label htmlFor="ttp_short_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Did you know(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      didYouKnowAREditorRef.current = editor
                    }}
                    onEditorChange={e => values.ttp_short_description_ar = e}

                    initialValue={values.ttp_short_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_short_description_ar && touched.ttp_short_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.ttp_short_description_ar}</p>
                  ) : null}
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className="flex flex-row mt-5 gap-5"> */}

            {/* <div className="basis-3/4">
                <label htmlFor="ttp_smart_report_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Comment(EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      smartReportCommentENEditorRef.current = editor;
                    }}
                    onEditorChange={e => values.ttp_smart_report_description_en = e}

                    initialValue={values.ttp_smart_report_description_en}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_smart_report_description_en ? (
                    <p className="text-red-600 text-sm">{errors.ttp_smart_report_description_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="ttp_short_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Smart Report Comment(AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      smartReportCommentAREditorRef.current = editor
                    }}
                    onEditorChange={e => values.ttp_smart_report_description_ar = e}
                    initialValue={values.ttp_smart_report_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_smart_report_description_ar && touched.ttp_smart_report_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.ttp_smart_report_description_ar}</p>
                  ) : null}
                </div>
              </div> */}

            {/* </div> */}
            <div className="flex flex-row mt-5 gap-5">
              {/* <div className="basis-3/4">
                <label htmlFor="ttp_tips_description_en" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tips (EN)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      tipsENEditorRef.current = editor;
                    }}
                    onEditorChange={e => values.ttp_tips_description_en = e}
                    initialValue={values.ttp_tips_description_en}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_tips_description_en ? (
                    <p className="text-red-600 text-sm">{errors.ttp_tips_description_en}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="ttp_tips_description_ar" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tips (AR)</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => {
                      tipsAREditorRef.current = editor
                    }}
                    onEditorChange={e => values.ttp_tips_description_ar = e}
                    initialValue={values.ttp_tips_description_ar}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                  {errors.ttp_tips_description_ar && touched.ttp_tips_description_ar ? (
                    <p className="text-red-600 text-sm">{errors.ttp_tips_description_ar}</p>
                  ) : null}
                </div>
              </div> */}

            </div>
            <div className="flex flex-row mt-5 gap-5">
            </div>
            <div className="flex flex-row mt-5 gap-5">
              <div className="basis-3/4">
                <label htmlFor="fk_rr_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Reference Range</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {referanceList.length > 0 ? (
                    <Select
                      name="mrr_name_en"
                      id="mrr_id"
                      placeholder="Select Reference Range"
                      value={selectedReferanceRange}
                      onChange={(e) => {
                        console.log(e)
                        values.fk_rr_id = e.value;
                        setSelectedReferanceRange(e)
                      }}
                      // onBlur={handleBlur}
                      components={animatedComponents}
                      options={referanceList ? referanceList.map(v => ({ value: v.mrr_id, label: v.mrr_name_en })) : []}
                      classNamePrefix="select"
                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />) : (<></>)}
                  {errors.fk_rr_id && touched.fk_rr_id ? (
                    <p className="text-red-600 text-sm">{errors.fk_rr_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="ttp_test_code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Code</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Enter Test-Parameter Code"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="ttp_test_code"
                    id="ttp_test_code"
                    value={values.ttp_test_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.ttp_test_code && touched.ttp_test_code ? (
                    <p className="text-red-600 text-sm">{errors.ttp_test_code}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-5 gap-5">
              <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img EN : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleEnFileChange} />
                  {imgEnfileNamePath ? (
                    <img width={40} height={20} src={imgEnfileNamePath} alt={values.ttp_img_en} />) : (<></>)}

                  {enProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${enProgress}%`}</div>
                  </div></>) : (<></>)}
                </div>
              </div>
              <div className="basis-3/4">
                <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">Img AR : </label>
                <div className="mt-1 ">
                  <input type="file" accept="image/*" onChange={handleArFileChange} />
                  {imgArfileNamePath ? (
                    <img width={40} height={20} src={imgArfileNamePath} alt={values.ttp_img_ar} />) : (<></>)}
                  {arProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${arProgress}%`}</div>
                  </div></>) : (<></>)}
                </div>
              </div>
              <h6 onClick={e => {
                onPreviewClick();
              }
              }
                className="underline cursor-pointer">Preview</h6>



            </div>
            <hr />
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddTestParameter;
