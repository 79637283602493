import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { shiftMasterSchema } from "../../../schemas";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { shift_columns } from "../../../components/tables/tableheader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Api from "../../../ApiServices/Api";

const time = Array.from({ length: 24 }, (_, i) => i);

const ShiftMasterList = () => {
  const pages = [{ title: "Shift", href: "/shift", module_id: 50 }];
  const initialValues = {
    shift_name: "",
    start_date: "",
    end_date: "",
  };

  const [formShiftMaster, setFormShiftMaster] = useState(initialValues);
  const [shiftData, setShiftData] = useState([]);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shiftId, setShiftId] = useState();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    shift_id: null,
  });

  useEffect(() => {
    document.title = "Labib | Shift";
    getAllShift();
  }, []);

  const getAllShift = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get("/api/dashboard/shift");
      if (data.status) {
        setShiftData(data.data);
        toast.success(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setShiftId(id);
      const initialValues = {
        shift_name: obj.name,
        start_date: obj.start_time ? formatTimeFromISO(obj.start_time) : "",
        end_date: obj.end_time ? formatTimeFromISO(obj.end_time) : "",
      };
      setFormShiftMaster(initialValues);
    } else {
      setShiftId("");
      setFormShiftMaster(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  function formatTimeFromISO(isoString) {
    const date = new Date(isoString);
    return date.getHours();
  }

  const onDeleteOpen = (shift_id) => {
    setConfirmationModal({ shift_id, status: true });
  };

  const onDeleteShift = async (shift_id) => {
    try {
      setIsLoading(true);
      const { data } = await Api().delete(`/api/dashboard/shift/${shift_id}`);
      if (data.status == 200) {
        toast.success(data.message);
      } else {
        toast.error(data.message)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setConfirmationModal({ shift_id: null, status: false });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formShiftMaster,
    validationSchema: shiftMasterSchema,
    onSubmit: async (values, action) => {
      console.log("Submit Clicked", values);
      try {
        if (shiftId) {
          let payload = {
            id: shiftId,
            name: values.shift_name,
            startTime: values.start_date,
            endTime: values.end_date,
          };
          try {
            setIsLoading(true);
            const { data } = await Api().patch("/api/dashboard/shift", payload);
            if (data.status == 200) {
              action.resetForm();
              if (modalOpenFlage === true) {
                getAllShift();
                setmodalOpenFlage(false);
              }
              toast.success(data.message);
            } else {
              if (modalOpenFlage === true) {
                setmodalOpenFlage(false);
              }
              toast.error(data.message);
            }
            setIsLoading(false);
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        } else {
          let payload = {
            name: values.shift_name,
            startTime: values.start_date,
            endTime: values.end_date,
          };
          MasterServices.saveShift(payload).then((res) => {
            const { data, status } = res;
            if (status == 200) {
              action.resetForm();
              if (modalOpenFlage === true) {
                getAllShift();
                setmodalOpenFlage(false);
              }
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
              action.resetForm();
              if (modalOpenFlage === true) {
                setmodalOpenFlage(false);
              }
            }
          });
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteShift(confirmationModal.shift_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Shift</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Shift.</p>
      </div>
      <div className="mt-4 flex items-center justify-end gap-3">
        <CustomButton onClick={() => handleDrawer("add", "", {})}>
          Add Shift
        </CustomButton>
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={shift_columns({ onDeleteOpen, handleDrawer })}
          data={shiftData && shiftData.length > 0 ? shiftData : []}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {shiftId ? "Update" : "Add"} Shift
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Name
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.shift_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Shift Master Name"
                                    name="shift_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.shift_name && touched.shift_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.shift_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="start_date"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Start Time
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="start_date"
                                    id="start_date"
                                    value={values.start_date}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      handleChange(e);
                                      setFieldValue("end_date", "");
                                    }}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null} disabled>
                                      {" "}
                                      Select Start Time{" "}
                                    </option>
                                    {time?.map((t, i) => (
                                      <option key={i} value={t}>
                                        {t}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.start_date && touched.start_date ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.start_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="end_date"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    End Time
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="end_date"
                                    id="end_date"
                                    value={values.end_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null} disabled>
                                      {" "}
                                      Select Start Time{" "}
                                    </option>
                                    {time?.map((t, i) => (
                                      <option key={i} value={t}>
                                        {t}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.end_date && touched.end_date ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.end_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {shiftId ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default ShiftMasterList;
