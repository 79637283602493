import React, { useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";

import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;

  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  onPageChange,
  columns,
  data,
  table_id,
  printRef,
  isSearchBar,
  zoom,
  pagination,
}) {
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
    //state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pagination,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    setPageSize(25)
  }, []);

  // Function to handle page change API
  const handlePrevious = () => {
    if (pagination?.currentPage > 1) {
      onPageChange(pagination?.currentPage - 1);
    }
  };

  const handleNext = () => {
    if (pagination?.currentPage < pagination?.totalPages) {
      onPageChange(pagination?.currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const { currentPage, totalPages } = pagination;
    const pageNumbers = [];

    // Show first two pages
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push(2);

      // Ellipsis after the second page
      if (currentPage > 5) {
        pageNumbers.push("...");
      }

      // Pages around the current page
      for (
        let i = Math.max(3, currentPage - 1);
        i <= Math.min(totalPages - 2, currentPage + 1);
        i++
      ) {
        pageNumbers.push(i);
      }

      // Ellipsis before the last two pages
      if (currentPage < totalPages - 4) {
        pageNumbers.push("...");
      }

      // Last two pages
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };
   
  // Render the UI for your table
  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-2 mt-5">
        {isSearchBar && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>

      {/* TABLE UI */}
      <div
        id={table_id}
        ref={printRef}
        className={`mt-4 flex flex-col ${zoom ? zoom : ""}`}
      >
        <div
          dir="ltr"
          className="-my-2 overflow-x-auto transform -mx-4 sm:-mx-6 lg:-mx-8"
        >
          <div
            dir="ltr"
            className="py-2 align-middle inline-block tranform min-w-full sm:px-6 lg:px-8"
          >
            <div className="shadow overflow-hidden overflow-x-auto border-b border-gray-200">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className={`${
                            index === 8 ? "" : ""
                          }  group px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider ${
                            index === 10 || index === 12 || index === 13
                              ? "sample-processing-tr"
                              : ""
                          }`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...column.getHeaderProps({
                            style: { width: 185 },
                          })}
                        >
                          <div className="flex items-center justify-center">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page?.length > 0 ? (
                    page.map((row, i) => {
                      // new

                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`${
                                  index === 10 || index === 12 || index === 13
                                    ? ""
                                    : ""
                                } px-4 py-2 text-center  ${
                                  index === 8 ? "" : ""
                                } ddd`}
                                // className={`px-6 py-4 text-left sample-processing-td whitespace-nowrap`}
                                role="cell"
                              >
                                {cell.column.Cell.name === "defaultRenderer" ? (
                                  <div className="text-sm font-medium text-gray-500 ">
                                    {cell.render("Cell")}
                                  </div>
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                      No Data Found
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/******* Pagination *********/}
      {pagination && (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
          <button
            onClick={handlePrevious}
            disabled={pagination?.currentPage === 1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>

          <div className="flex items-center justify-center space-x-2">
            {renderPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => handlePageClick(page)}
                disabled={page === "..." || page == pagination?.currentPage}
                className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium hover:border-gray-300 ${
                  page === pagination?.currentPage
                    ? "border-cyan-500 text-cyan-600"
                    : page === "..."
                    ? "cursor-default border-transparent text-gray-500"
                    : "border-transparent text-gray-500 hover:text-gray-700"
                }`}
              >
                {page}
              </button>
            ))}
          </div>

          <button
            onClick={handleNext}
            disabled={pagination?.currentPage === pagination?.totalPages}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 focus-visible:outline-none hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </nav>
      )}
    </>
  );
}

export default Table;
