import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/tableNew";
import { reservation_columns, reservation_columns_new } from "../../../components/tables/tableheader";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toast";
import { reservation_status_colors } from "./reservationStatusColorCodes";
import moment from "moment";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReservationServices from "../../../ApiServices/ReservationServices";

let filterInitialValues = {
  reservation_created_from: "",
  reservation_created_to: "",
  schedule_date_from: "",
  schedule_date_to: "",
  reservation_status: "",
  crm_status: "",
  reservation_id: "",
  membership_id: "",
  patient_name: "",
};

const ReservationListNew = () => {
  const pages = [
    { title: "Reservation List", href: "/reservation-list", module_id: 10 },
  ];
  const [filterFrom, setFilterFrom] = useState("");
  const [filterScheduleFrom, setFilterScheduleFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filterScheduleTo, setFilterScheduleTo] = useState("");
  const [filterMembership, setFilterMembership] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [crmFilterStatus, setCrmFilterStatus] = useState("");
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterReservationId, setFilterReservationId] = useState("");
  const [filterFlag, setFilterFlag] = useState(false);
  const [reservationList, setReservationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    reservation_id: null,
    reservation_is_active: false,
  });
  const [pagination, setPagination] = useState("");

  useEffect(() => {
    document.title = "Labib | Reservation List";
    getData(1, filterInitialValues);
  }, []);

  useEffect(() => {
    if (filterScheduleFrom) {
      const currentDate = new Date();
      setFilterScheduleTo(moment(currentDate).format("YYYY-MM-DD"));
    }
  }, [filterScheduleFrom]);

  const onFilter = (page) => {
    filterInitialValues = {
      reservation_created_from: filterFrom ? filterFrom : "",
      reservation_created_to: filterTo ? filterTo : "",
      schedule_date_from: filterScheduleFrom ? filterScheduleFrom : "",
      schedule_date_to: filterScheduleTo ? filterScheduleTo : "",
      reservation_status: filterStatus ? filterStatus : "",
      patient_name: filterPatientName ? filterPatientName : "",
      reservation_id: filterReservationId ? filterReservationId : "",
      membership_id: filterMembership ? filterMembership : "",
      crm_status: crmFilterStatus ? crmFilterStatus : ""
    };
    getData(page, filterInitialValues);
  };

  const onFilterClear = () => {
    setFilterFrom("");
    setFilterTo("");
    setFilterStatus("");
    setFilterPatientName("");
    setFilterReservationId("");
    setFilterScheduleFrom("");
    setFilterScheduleTo("");
    setFilterMembership("");
    setCrmFilterStatus('')
    getData(1);
  };

  const getData = (page, payload) => {
    try {
      setIsLoading(true);
      let  query = {
        page_size: pagination?.page_size ? pagination.page_size : 10,
        page_no: page ? page : pagination.page_no
      };
      ReservationServices.getAllReservations(query, payload)
        .then((res) => {
          const { data, status } = res;
          if (status == 200) {
            setReservationList(data?.data?.reservations);
            setPagination(data?.data?.pagination)
            toast.success(data?.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
          console.log("payload", data.data);
        })
        .catch((err) => {
          console.error(err);
          toast.error();
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    onFilter(page)
  };

  const editReservation = (rs_val) => {
    localStorage.setItem("edit_booking_reservation_id", rs_val.reservation_id);
    localStorage.setItem(
      "edit_booking_reservation_user_details",
      JSON.stringify(rs_val)
    );
  };

  const generateInvoiceReport = async (payload) => {
    let singleReservationDetails = await getSingleReservationDetails(payload);
    payload.singleReservationDetails = singleReservationDetails;
    payload.collection_loc_relation = null;
    payload.m_ot_as_ref_clinic_relation = null;
    let res = await ReservationServices.generateInvoiceReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      window.open(res.data.data.file_path, "_blank");
      getData(1, filterInitialValues);
    } else {
      toast.error(res.data.message);
    }
  };

  const getSingleReservationDetails = async (payload) => {
    return await ReservationServices.getSingleReservation(
      payload?.reservation_id
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          return data.data;
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "getAllPatientList catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  };

  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <div className="flex items-center space-x-2">
            <div className="relative">
              <button
                onClick={() => {
                  setFilterFlag(filterFlag ? false : true);
                }}
                className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
              >
                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </div>
                  <div className="hidden sm:block">
                    Filters {filterFlag ? "On" : "Off"}
                  </div>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.reservation_is_active ? "Disable " : "Activate") +
          " this Reservation?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        // onDelete={() =>
        //   onDeleteReservation(
        //     confirmationModal.reservation_id,
        //     confirmationModal.reservation_is_active
        //   )
        // }
      />
      {filterFlag && (
        <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2 relative">
          <div className="space-y-6 sm:space-y-5">
            <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-2">
              <button
                type="button"
                className="text-red-500 text-right hover:text-red-700 font-bold py-1 px-1 rounded absolute top-2 right-2"
                onClick={() => setFilterFlag(filterFlag ? false : true)}
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
              <div className="basis-3/4">
                <label
                  htmlFor="filterFrom"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation From
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="date"
                    name="filterFrom"
                    id="filterFrom"
                    value={filterFrom}
                    onChange={(e) => {
                      setFilterFrom(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterTo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation To
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="date"
                    name="filterTo"
                    id="filterTo"
                    value={filterTo}
                    onChange={(e) => {
                      setFilterTo(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterStatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Status
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={filterStatus}
                    onInput={(e) => {
                      setFilterStatus(e.target.value);
                    }}
                    name="filterStatus"
                    id="filterStatus"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  >
                    <option value={""}>Select Status</option>
                    <option value="1">Confirmed</option>
                    <option value="2">Paid</option>
                    <option value="3">Refunded</option>
                    <option value="4">Sample Generated</option>
                    <option value="5">Result Generated</option>
                    <option value="6">Under Processing</option>
                  </select>
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="crmFilterStatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Crm Status
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    value={crmFilterStatus}
                    onInput={(e) => {
                      setCrmFilterStatus(e.target.value);
                    }}
                    name="crmFilterStatus"
                    id="crmFilterStatus"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                  >
                    <option value={""}>Select Status</option>
                    <option value="SCHEDULED">Scheduled</option>
                    <option value="RESCHEDULED">Rescheduled</option>
                    <option value="FIRST_CALL">1st Call, Not Answered</option>
                    <option value="SECOND_CALL">2nd Call, Not Answered</option>
                    <option value="THIRD_CALL">3rd Call, Not Answered</option>
                    <option value="CANCELLED">Cancelled</option>
                    <option value="POSTPONED">Postponed</option>
                  </select>
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterScheduleFrom"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Schedule From
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="date"
                    name="filterScheduleFrom"
                    id="filterScheduleFrom"
                    value={filterScheduleFrom}
                    onChange={(e) => {
                      setFilterScheduleFrom(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterScheduleTo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Schedule To
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="date"
                    name="filterScheduleTo"
                    id="filterScheduleTo"
                    value={filterScheduleTo}
                    onChange={(e) => {
                      if (!filterScheduleFrom) {
                        return toast.error("Please select schedule from date");
                      } else {
                        setFilterScheduleTo(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="basis-3/4">
                <label
                  htmlFor="filterPatientName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    value={filterPatientName}
                    onInput={(e) => {
                      setFilterPatientName(e.target.value);
                    }}
                    placeholder="Search By Name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="filterPatientName"
                    id="filterPatientName"
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterReservationId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reservation No.
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Reservation No."
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="filterReservationId"
                    id="filterReservationId"
                    value={filterReservationId}
                    onInput={(e) => {
                      setFilterReservationId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="basis-3/4">
                <label
                  htmlFor="filterMembership"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Membership ID
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Search By Membership id"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    type="text"
                    name="filterMembership"
                    id="filterMembership"
                    value={filterMembership}
                    onInput={(e) => {
                      setFilterMembership(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="text-end flex items-center gap-5 justify-start flex-row-reverse">
              <button
                type="button"
                className={`rounded-md border border-gray-300 bg-cyan-600 py-2 px-4 text-sm font-medium text-gray-100 shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                onClick={() => {
                  onFilter(1);
                }}
                size="default"
                variant="outlined"
              >
                Search
              </button>
              <button
                type="button"
                className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                onClick={() => {
                  onFilterClear();
                }}
                size="default"
                variant="outlined"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>
            {isLoading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                zoom="zoom-100"
                onPageChange={handlePageChange}
                pagination={pagination}
                isSearchBar={false}
                columns={reservation_columns_new({
                  editReservation,
                  generateInvoiceReport,
                  reservation_status_colors,
                })}
                data={reservationList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationListNew;
