/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-toast";
import { supabase } from "../../supabase";
import {
	GET_ALL_USER_BEGIN,
	GET_ALL_USER_SUCCESS,
	GET_ALL_USER_ERROR,
	ADD_USER_BEGIN,
	ADD_USER_SUCCESS,
	ADD_USER_ERROR,
	GET_USER_BEGIN,
	GET_USER_SUCCESS,
	GET_USER_ERROR,
	UPDATE_USER_BEGIN,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_ERROR,
	DELETE_USER_BEGIN,
	DELETE_USER_SUCCESS,
	DELETE_USER_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";
import { getParams } from "../../helper/commonHelper";

const API = process.env.REACT_APP_API_URL;

export const getAllUsers = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: GET_ALL_USER_BEGIN,
		});
		
		let param = getParams(payload)
		
		const { data, status } = await Api().get(`/api/dashboard/user?${param}`);

		if (status == 200) {
			
			let updatedUsers = data?.data
			updatedUsers?.forEach((element) => {
				element.label = element.user_first_name
				element.value = element.user_id
			});
			
			dispatch({
				type: GET_ALL_USER_SUCCESS,
				data: data ? data.data : [],
				meta: data.meta
			});

		  } else {
			toast.error("Fatal Error Please Contact Admin")
		  }
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		console.log(error);
		dispatch({
			type: GET_ALL_USER_ERROR,
			error: msg,
		});
	}
};

export const addUser = (body) => async (dispatch) => {
	try {
		dispatch({
			type: ADD_USER_BEGIN,
		});

		const user_status = true;
		console.log("Priyanshu SIngh=>", body);

		const { data } = await supabase.from("users").insert({
			user_first_name: body.user_first_name,
			user_last_name: body.user_last_name,
			user_role: body.user_role,
			user_email: body.user_email,
			user_mobile: body.user_mobile,
			user_password: body.user_password,
			user_location_id: body.user_location_id,
			user_status: body.user_status,
			gender: body.user_gender,
			referral_code: body.user_referral_code,
			doc_details: body.doc_details,
		}).select(`
        *,m_role:user_role (
          role_name
        ),
    
        
        location:user_location_id (
          location_name
        )
      `);

		if (data.length > 0) {
			const patientData = await supabase.from("patients").insert({
				patient_first_name: body.user_first_name,
				patient_last_name: body.user_last_name,
				patient_email: body.user_email,
				patient_mobile: body.user_mobile,
				patient_location_id: body.user_location_id,
				patient_status: body.user_status,
				gender: body.user_gender,
				referral_code: body.user_referral_code,
				doc_details: body.doc_details,
				fk_user_id: data[0].user_id,
			});
		}

		console.log(data[0]);
		toast.success("User added successfully!");

		dispatch({
			type: ADD_USER_SUCCESS,
			data: data[0],
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: ADD_USER_ERROR,
			error: msg,
		});
	}
};

export const getUserWithId = (user_id) => async (dispatch) => {
	try {
		dispatch({
			type: GET_USER_BEGIN,
		});

		const { data } = await supabase
			.from("users")
			.select()
			.eq("user_id", parseInt(user_id))
			.maybeSingle();

		console.log("GET_USER_BEGIN : ", data);
		dispatch({
			type: GET_USER_SUCCESS,
			data: data,
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: GET_USER_ERROR,
			error: msg,
		});
	}
};

export const updateUser = (user_id, body) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_USER_BEGIN,
		});
		console.log();
		const ExistpatientData = await supabase
			.from("patients")
			.select(`*`)
			.eq("fk_user_id", user_id);
		console.log("Jio=>", ExistpatientData);
		// return false;

		if (ExistpatientData.data.length <= 0) {
			const patientData = await supabase.from("patients").insert({
				fk_user_id: user_id,
				patient_first_name: body.user_first_name,
				patient_last_name: body.user_last_name,
				patient_email: body.user_email,
				patient_mobile: body.user_mobile,
				patient_location_id: body.user_location_id,
				patient_status: body.user_status,
				gender: body.user_gender,
				referral_code: body.user_referral_code,
				doc_details: body.doc_details,
			});
		} else if (ExistpatientData.data.length > 0) {
			const patientData = await supabase
				.from("patients")
				.update({
					fk_user_id: user_id,
					patient_first_name: body.user_first_name,
					patient_last_name: body.user_last_name,
					patient_email: body.user_email,
					patient_mobile: body.user_mobile,
					patient_location_id: body.user_location_id,
					patient_status: body.user_status,
					gender: body.user_gender,
					referral_code: body.user_referral_code,
					doc_details: body.doc_details,
				})
				.eq("fk_user_id", user_id);
		}
		const { data, error } = await supabase.from("users").upsert({
			user_id,
			user_first_name: body.user_first_name,
			user_last_name: body.user_last_name,
			user_role: body.user_role,
			user_email: body.user_email,
			user_mobile: body.user_mobile,
			user_password: body.user_password,
			user_location_id: body.user_location_id,
			user_status: body.user_status,
			gender: body.user_gender,
			referral_code: body.user_referral_code,
			doc_details: body.doc_details,
		}).select(`
    *,m_role:user_role (
      role_name
    ),

    
    location:user_location_id (
      location_name
    )
  `);

		console.log(data[0]);
		toast.success("User updated successfully!");
		dispatch({
			type: UPDATE_USER_SUCCESS,
			data: data[0],
		});
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: UPDATE_USER_ERROR,
			error: msg,
		});
	}
};

export const deleteUser = (user_id) => async (dispatch) => {
	try {
		dispatch({
			type: DELETE_USER_BEGIN,
		});
		const user_status = 0;
		const { data, error } = await supabase.from("users").upsert({
			user_id,
			user_status,
		}).select(`
    *,m_role:user_role (
      role_name
    ),

    
    location:user_location_id (
      location_name
    )
  `);

		if (data) {
			toast.success("User deleted successfully!");
			dispatch({
				type: DELETE_USER_SUCCESS,
				data: user_id,
			});
		}
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: DELETE_USER_ERROR,
			error: msg,
		});
	}
};
