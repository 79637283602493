import { Schedule } from "@mui/icons-material";

export const reservation_status_colors = {
  Confirmed: "text-cyan-600 bg-cyan-100",
  SCHEDULED: "text-blue-600 bg-blue-100",
  Paid: "text-orange-600 bg-orange-100",
  Refunded: "text-red-600 bg-red-100",
  "Samples Generated": "text-yellow-600 bg-yellow-100",
  "Sample Collected": "text-purple-600 bg-purple-100",
  "Under Processing": "text-fuchsia-400",
  "Results Generated": "text-green-500",
};