
import React, { useState, useEffect, } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../../components/spinners/Spinner';
import Table from '../../../../components/tables/table';
import TableEmpty from '../../../../components/tables/tableEmpty';
import { accumulated_revenue_report } from '../../../../components/tables/tableheader';
import MasterServices from '../../../../ApiServices/MasterServices'
import moment from 'moment';
import Select from 'react-select'
import ReportServices from '../../../../ApiServices/ReportServices';
import { toast } from 'react-toast';
import { utils, writeFile } from 'xlsx';
import { capitalise } from '../../../../utilities/utilities';
import { convertToAnotherTimezone } from '../../../../helper/commonHelper';
import queryString from 'query-string';

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterHosLoc: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
};

const AccumulatedRevenueReport = () => {
  const pages = [{ title: "Accumulated Revenue Report", href: "/accumulated-revenue-report", module_id: 0 }];
  const [isLoading, setIsLoading] = useState(true);
  const [accumulatedRevenueReport, setAccumulatedRevenueReport] = useState([])
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterHos, setFilterHos] = useState('');
  const [filterHosLoc, setFilterHosLoc] = useState('');
  const [filterBkType, setFilterBkType] = useState('');
  const [filterOrg, setFilterOrg] = useState('');
  const [filterSampleId, setFilterSampleId] = useState('');
  const [filterPatientMob, setFilterPatientMob] = useState('');
  const [filterPatientIdentification, setFilterPatientIdentification] = useState('');
  const [filterPatientName, setFilterPatientName] = useState('');
  const [filterPatientPassport, setFilterPatientPassport] = useState('');
  const [filterTestType, setFilterTestType] = useState('');
  const [filterPatientId, setFilterPatientId] = useState('');
  const [filterReservationId, setFilterReservationId] = useState('');
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalLocationList, setHospitalLocationList] = useState([]);
  const [filterMrn, setFilterMrn] = useState('');
  const [accumulatedRevenueReportFilters, setAccumulatedRevenueReportFilters] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({ page_size: 25, page_no: 1 });

  const handlePageChange = (page) => {
		getAllAccumulatedReservationReport(page)
	  };

  useEffect(() => {
    getAllAccumulatedReservationReport(queryString.stringify(filterInitialValues));
    getHospitalList();
  }, [])

  const onFilter = () => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : '',
      filterTo: filterTo ? filterTo : '',
      filterStatus: filterStatus ? filterStatus : '',
      filterHos: filterHos ? filterHos : '',
      filterHosLoc: filterHosLoc ? filterHosLoc : '',
      filterBkType: filterBkType ? filterBkType : '',
      filterOrg: filterOrg ? filterOrg : '',
      filterSampleId: filterSampleId ? filterSampleId : '',
      filterPatientMob: filterPatientMob ? filterPatientMob : '',
      filterPatientIdentification: filterPatientIdentification ? filterPatientIdentification : '',
      filterPatientName: filterPatientName ? filterPatientName : '',
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : '',
      filterTestType: filterTestType ? filterTestType : '',
      filterPatientId: filterPatientId ? filterPatientId : '',
      filterReservationId: filterReservationId ? filterReservationId : '',
      filterMrn: filterMrn ? filterMrn : '',
    };

    // console.log("filterInitialValues=>", filterInitialValues)
    getAllAccumulatedReservationReport(queryString.stringify(filterInitialValues));
  }
  const onFilterClear = () => {
    setFilterFrom('');
    setFilterTo('');
    setFilterStatus('');
    setFilterHos('');
    setFilterHosLoc('');
    setFilterBkType('');
    setFilterOrg('');
    setFilterSampleId('');
    setFilterPatientMob('');
    setFilterPatientIdentification('');
    setFilterPatientName('');
    setFilterPatientPassport('');
    setFilterTestType('');
    setFilterPatientId('');
    setFilterReservationId('');
    setFilterMrn('');
    filterInitialValues = {
      filterFrom: "",
      filterTo: "",
      filterStatus: "",
      filterHos: "",
      filterHosLoc: "",
      filterBkType: "",
      filterOrg: "",
      filterSampleId: "",
      filterPatientMob: "",
      filterPatientIdentification: "",
      filterPatientName: "",
      filterPatientPassport: "",
      filterTestType: "",
      filterPatientId: "",
      filterReservationId: "",
      filterMrn: "",
    };
    getAllAccumulatedReservationReport(queryString.stringify(filterInitialValues));
  }

  const getHospitalList = () => {
    MasterServices.getAllStatusHospitals(queryString.stringify({ search: 1 }))
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setHospitalList(data.data);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getHospitalList catch Error";
        toast.error(msg);
      });
  }

  const getAllHospitalBaseLocation = (loc_id) => {
    MasterServices.getAllHospitalBaseLocation(queryString.stringify({ search: 1, loc_id: loc_id }))
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setHospitalLocationList(data.data);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getAllHospitalBaseLocation catch Error";
        toast.error(msg);
      });
  }


  const handleExport = (excel_type) => {

    if (accumulatedRevenueReport?.length > 0) {

      let TestPackList = accumulatedRevenueReport;
      let newSheetdata = [];
      TestPackList.map((obj, index) => {
        let is_refunded = obj.is_refunded;
        let Lab = (obj.collection_loc_relation?.m_hospital_relation?.hos_name_en) + ' - ' + (obj.collection_loc_relation?.loc_name_en);
        let CCAMT = obj.CCAMT;
        let rev_amount = (obj.rev_amount) && !isNaN(obj.rev_amount) ? parseFloat(obj.rev_amount) : 0;
        let rev_vat_amount = (obj.rev_vat_amount) && !isNaN(obj.rev_vat_amount) ? parseFloat(obj.rev_vat_amount) : 0;
        let rev_total_discount = (obj.rev_total_discount) && !isNaN(obj.rev_total_discount) ? parseFloat(obj.rev_total_discount) : 0;
        let rev_discounted_amount = (obj.rev_discounted_amount) && !isNaN(obj.rev_discounted_amount) ? parseFloat(obj.rev_discounted_amount) : 0;
        let totAmt = parseFloat(rev_amount) + parseFloat(rev_vat_amount);
        let discount = parseFloat(rev_total_discount) + parseFloat(rev_discounted_amount);
        let AMT = totAmt - discount;
        let date_time = new Date(obj.date_time);
        const newData = {
          Lab: Lab,
          Date: moment((obj.date_time)).format("DD-MM-YYYY"),
          Day: date_time.toLocaleDateString(undefined, { weekday: 'long' }),
          Total_Net_SAR: (obj.rev_amount) ? (obj.rev_amount) : 0,
          Invoice_Discount: (obj.rev_discounted_amount) ? (obj.rev_discounted_amount) : 0,
          Test_Pack_Discount: (obj.rev_total_discount) ? (obj.rev_total_discount) : 0,
          Total_VAT_SAR: (obj.rev_vat_amount) ? (obj.rev_vat_amount) : 0,
          Net_Bill_Amt_SAR: (AMT) ? (AMT) : 0,
          Total_No_Of_Patient: (obj.PaitentCount) ? (obj.PaitentCount) : 0,
          Payment_Type: (obj.paymentType) ? (obj.paymentType) : '',
          Paid_In_Cash: (obj.cashAmt) ? (obj.cashAmt) : 0,
          Paid_In_Card: (obj.cardAmt) ? (obj.cardAmt) : 0,
          Refund_In_Cash: (is_refunded) ? (obj.CCAMT) : 0,
          Net_Cash: (obj.CCAMT) ? (obj.CCAMT) : 0,
          Cheque: '',
          Bank_Transfer: '',
          Bank_transfer_Number: '',
          Name_of_bank_Account: '',
          Adjusted_From_Advance: '',
          Advance_Recieved: '',
          Credit: '',
          Ins_Credit: '',
          Corporate_Credit: '',
          Total_SAR: Math.round(AMT),
        }
        newSheetdata.push(newData);
      })
      let fileName = 'Accumulated_Revenue_Report_' + '.' + excel_type;

      const headings = [['Lab', 'Date', 'Day', 'Total_Net_SAR', 'Invoice_Discount', 'Test_Pack_Discount', 'Total_VAT_SAR', 'Net_Bill_Amt_SAR', 'Total_No_Of_Patient', 'Payment_Type', 'Paid_In_Cash', 'Paid_In_Card', 'Refund_In_Cash', 'Net_Cash', 'Cheque', 'Bank_Transfer', 'Bank_transfer_Number', 'Name_of_bank_Account', 'Adjusted_From_Advance', 'Advance_Recieved', 'Credit', 'Ins_Credit', 'Corporate_Credit', 'Total_SAR']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);

    } else {
      toast.error("Data Not Found!");
    }

  }
  async function getAllAccumulatedReservationReport(page) {
    // console.log(resUserReportFilters)
    // setIsLoading(true)
    
    let payload = {
      ...filterInitialValues,
      search: "All",
      search_text: globalFilter ? globalFilter : "",
      page_size: pagination?.page_size ? pagination.page_size : 20,
      page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
    };
    try {
      const { data, status } = await ReportServices.getAllAccumulatedReservationReport(payload);
      if (status === 200) {
        setAccumulatedRevenueReport(data.data.getAllReservationList)
        setPagination(data.meta);
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllAccumulatedReservationReport(1)
  }, [globalFilter]);



  const generateObjectToPdf = async () => {
    // setIsLoading(true)
    try {
        console.log("accumulatedRevenueReport", accumulatedRevenueReport)
        
        let newSheetdata = [];
        if (accumulatedRevenueReport?.length > 0) {
          accumulatedRevenueReport.map((obj, index) => {
                const newData = {
                  SN: index+1,
                  Lab: capitalise(obj.collection_loc_relation?.m_hospital_relation?.hos_name_en) + " - " + capitalise(obj.collection_loc_relation?.loc_name_en),
                  Date: moment((obj.date_time)).format("DD-MM-YYYY"),
                  Day: new Date(obj.date_time).toLocaleDateString(undefined, { weekday: 'long' }),
                    TotalNetSAR: (obj.rev_amount ? obj.rev_amount : 0),
                    InvoiceDiscount: (obj.rev_discounted_amount ? obj.rev_discounted_amount : 0),
                    TestPackDiscount: (obj.rev_total_discount ? obj.rev_total_discount : 0),
                    TotalVATSAR: (obj.rev_vat_amount ? obj.rev_vat_amount : 0)
                }
                newSheetdata.push(newData);
            })
        }

        let payload = {
                //! Ongoing
                // columns: ['SN.', 'Lab', 'Date', 'Day', 'Total Net - SAR', 'Invoice Discount', 'Test/Pack Discount', 'Total VAT - SAR', 'Net Bill Amt-SAR', 'Total Patients', 'Payment Type', 'Paid in Cash', 'Paid in Card', 'Refund in Cash', 'Net Cash', 'Cheque', 'Bank Transfer', 'Bank Transfer No.', 'Name of Bank Account', 'Adjusted from Advance', 'Advance Received', 'Credit', 'INS Credit', 'Corporate Credit', 'Total - SAR'],
                columns: ['SN.', 'Lab', 'Date', 'Day', 'Total Net - SAR', 'Invoice Discount', 'Test/Pack Discount', 'Total VAT - SAR'],
                pdf_name: 'Accumlated_Revenue_Report',
                rows: newSheetdata
    }
        const { data, status } = await MasterServices.generateObjectToPDF(payload);
        console.log(data)
        if (status === 200) {
            if(data?.data?.pdf_url != '') {
                // window.location.replace(data.pdf_url);
                window.open(data.data.pdf_url, '__blank');
            }
                // setResUserReportData(data.data)
        }
        // setIsLoading(false)
    } catch (error) {
        console.log(error)
        // setIsLoading(false)
    }
}

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Accumulated Revenue Report
        </h1>
      </div>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="space-y-6 sm:space-y-5">
          <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 mt-5">
            <div className="basis-3/4">
              <label
                htmlFor="filterFrom"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                From
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  type="date"
                  name="filterFrom"
                  id="filterFrom"
                  value={filterFrom}
                  onChange={(e) => {
                    setFilterFrom(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="basis-3/4">
              <label
                htmlFor="filterTo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                To
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  type="date"
                  name="filterTo"
                  id="filterTo"
                  value={filterTo}
                  onChange={(e) => {
                    setFilterTo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="basis-3/4">
              <label
                htmlFor=""
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Hospital
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  value={filterHos}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  name="fk_hos_id"
                  id="fk_hos_id"
                  onInput={(e) => {
                    setFilterHos(e.target.value);
                    getAllHospitalBaseLocation(e.target.value);
                  }}
                >
                  <option>Select Hospital</option>
                  {hospitalList.length > 0 ? (
                    hospitalList.map((hospital, i) => (
                      <option
                        selected={
                          hospital.hos_id === filterInitialValues.filterHos
                            ? "selected"
                            : ""
                        }
                        key={i}
                        value={hospital.hos_id}
                      >
                        {hospital.hos_name_en}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="basis-3/4">
              <label
                htmlFor=""
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Loc
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  value={filterHosLoc}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  name="fk_loc_id"
                  id="fk_loc_id"
                  onInput={(e) => {
                    setFilterHosLoc(e.target.value);
                  }}
                >
                  <option>Select Location</option>
                  {hospitalLocationList.length > 0 ? (
                    hospitalLocationList.map((hosLoc, i) => (
                      <option
                        selected={
                          hosLoc.loc_id === filterInitialValues.filterHosLoc
                            ? "selected"
                            : ""
                        }
                        key={i}
                        value={hosLoc.loc_id}
                      >
                        {hosLoc.loc_name_en}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>

            <div className="basis-3/4 mt-7">
              <button
                type="button"
                className={`mr-2 rounded-md bg-cyan-600 text-white border border-gray-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                onClick={() => {
                  onFilter();
                }}
                size="default"
                variant="outlined"
              >
                Search
              </button>
              <button
                type="button"
                className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                onClick={() => {
                  onFilterClear();
                }}
                size="default"
                variant="outlined"
              >
                Clear
              </button>
            </div>

            {accumulatedRevenueReport?.length > 0 ? (
              <div className="basis-3/4 mt-7">
                <button
                  onClick={() => handleExport("xlsx")}
                  className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Excel
                </button>

                <button
                  onClick={() => generateObjectToPdf()}
                  className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  PDF
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : accumulatedRevenueReport.length > 0 ? (
        <Table
          zoom='zoom-09'
          columns={accumulated_revenue_report()}
          data={accumulatedRevenueReport}
          pagination={pagination}
          onPageChange={handlePageChange}
          onGlobalFilterChange={setGlobalFilter}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}

export default AccumulatedRevenueReport