import React, { useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";

import { Button, PageButton } from "./paginationButtons";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  // console.log(preGlobalFilteredRows, "onFilterChange >>>>>")
  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // console.log(value, 'value')
  return (
    <label className="flex gap-x-2 items-baseline">
      {/* <span className="text-gray-700">Search: </span> */}
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  columns,
  data,
  pagination,
  table_id,
  printRef,
  zoom,
  onPageChange,
  onGlobalFilterChange, // New prop
}) {
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      pagination,
      // initialState: { pageIndex: 1 },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    setPageSize(50);
  }, []);

  const handlePrevious = () => {
    if (pagination?.currentPage > 1) {
      onPageChange(pagination?.currentPage - 1);
    }
  };

  const handleNext = () => {
    if (pagination?.currentPage < pagination?.totalPages) {
      console.log("pagination?.currentPage", pagination?.currentPage);

      onPageChange(pagination?.currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const { currentPage, totalPages } = pagination;
    const pageNumbers = [];

    // Show first two pages
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push(2);

      // Ellipsis after the second page
      if (currentPage > 5) {
        pageNumbers.push("...");
      }

      // Pages around the current page
      for (
        let i = Math.max(3, currentPage - 1);
        i <= Math.min(totalPages - 2, currentPage + 1);
        i++
      ) {
        pageNumbers.push(i);
      }

      // Ellipsis before the last two pages
      if (currentPage < totalPages - 4) {
        pageNumbers.push("...");
      }

      // Last two pages
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  // Render the UI for your table
  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-2 mt-5">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          // setGlobalFilter={setGlobalFilter}
          setGlobalFilter={(value) => {
            setGlobalFilter(value);
            if (onGlobalFilterChange) {
              onGlobalFilterChange(value);
            }
          }}

        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* TABLE UI */}
      <div
        id={table_id}
        ref={printRef}
        className={`mt-4 flex flex-col ${zoom ? zoom : ""}`}
      >
        <div className="-my-2 overflow-x-auto transform -mx-4 sm:-mx-6 lg:-mx-8 sss">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group ss px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...column.getHeaderProps({
                            style: { width: column.width },
                          })}
                        >
                          <div className="flex items-center justify-center text-center">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page?.length > 0 ? (
                    page.map((row, i) => {
                      // new

                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`px-6 py-4 whitespace-nowrap text-center `}
                                role="cell"
                              >
                                {cell.column.Cell.name === "defaultRenderer" ? (
                                  <div className="text-sm text-gray-500">
                                    {cell.render("Cell")}
                                  </div>
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                      No Data Found
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      {pagination &&
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <button
          onClick={handlePrevious}
          disabled={pagination?.currentPage === 1}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </button>

        <div className="flex items-center justify-center space-x-2">
          {renderPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(page)}
              disabled={page === "..."}
              className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium hover:border-gray-300 ${
                page === pagination?.currentPage
                  ? "border-cyan-500 text-cyan-600"
                  : page === "..."
                  ? "cursor-default border-transparent text-gray-500"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
            >
              {page}
            </button>
          ))}
        </div>

        <button
          onClick={handleNext}
          disabled={pagination?.currentPage === pagination?.totalPages}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 focus-visible:outline-none hover:text-gray-700"
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </nav>}

      {/* <div className="py-3 flex items-center justify-between">
        <div className="hidden md:inline-block lg:inline-block">
          <span>
            Page{' '}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
              defaultValue={state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                console.log(page, "Nitesh")
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            className="form-select appearance-none
          pl-4
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding bg-no-repeat
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            value={state.pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div> */}

      {/* <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
          <div className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium">
            Data{" "}
            <strong>
              {pagination?.pageSize} of {pagination?.totalData}
            </strong>{" "}
          </div>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {}
          <button
            key={key}
            onClick={(e) => {
              gotoPage(page);
            }}
            className={`${
              page == state.pageIndex
                ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }`}
          >
            {page + 1}
          </button>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pageOptions?.map((page, key) => (
            page < Math.ceil(pageOptions.length / 2) + 1 && (page + 1) < (state.pageIndex + 3) && (page + 2) > (state.pageIndex) ? (
              <button
                key={key}
                onClick={(e) => {
                  gotoPage(page);
                }}
                className={`${page == state.pageIndex ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
              >
                {page + 1}
              </button>
            ) : null
          ))}

          {pageOptions?.map((page, key) => (
            state.pageIndex > Math.ceil(pageOptions.length / 2) + 1 && (page == 0 || page == 1 || page == 2) ? (
              <button
                key={key}
                onClick={(e) => {
                  gotoPage(page);
                }}

                className={`${page == state.pageIndex ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
              >
                {page + 1}
              </button>
            ) : null
          ))}

          {pageOptions.length > 3 ?
            (
              <div>

                <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                  ...
                </span>


                {pageOptions?.map((page, key) => (
                  state.pageIndex <= Math.ceil(pageOptions.length / 2) - 1 && (page == pageOptions.length - 3 || page == pageOptions.length - 2 || page == pageOptions.length - 1) ? (
                    <button
                      key={key}
                      onClick={(e) => {
                        gotoPage(page);
                      }}

                      className={`${page == state.pageIndex ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
                    >
                      {page + 1}
                    </button>
                  ) : null
                ))}


                {pageOptions?.map((page, key) => (
                  page > Math.ceil(pageOptions.length / 2) && (page + 1) < (state.pageIndex + 3) && (page + 2) > (state.pageIndex) ? (
                    <button
                      key={key}
                      onClick={(e) => {
                        gotoPage(page);
                      }}
                      className={`${page == state.pageIndex ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
                    >
                      {page + 1}
                    </button>
                  ) : null
                ))}
              </div>
            ) : null
          }

        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav> */}
    </>
  );
}

export default Table;
