import React, { Fragment, useRef, useState, useEffect } from "react";
import Table from "../../../components/tables/table";
import { crm_sample__processing_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toast";
import SampleServices from "../../../ApiServices/SampleServices";
import queryString from "query-string";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useSelector } from "react-redux";

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
  filterOutsourcedClinic: "",
};

function CRMSampleProcessingTab() {
  const pageSize = 50;
  const {
    hospital: { list: hospitalList },
  } = useSelector((state) => state);
  const pages = [
    { title: "Sample Processing", href: `/sample-processing`, module_id: 12 },
  ];
  const [filterFrom, setFilterFrom] = useState("");
  const [filterOutsourcedClinic, setFilterOutsourcedClinic] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterHos, setFilterHos] = useState("");
  const [filterBkType, setFilterBkType] = useState("");
  const [filterOrg, setFilterOrg] = useState("");
  const [filterSampleId, setFilterSampleId] = useState("");
  const [filterPatientMob, setFilterPatientMob] = useState("");
  const [filterPatientIdentification, setFilterPatientIdentification] =
    useState("");
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterPatientPassport, setFilterPatientPassport] = useState("");
  const [filterTestType, setFilterTestType] = useState("");
  const [filterPatientId, setFilterPatientId] = useState("");
  const [filterReservationId, setFilterReservationId] = useState("");
  const [filterMrn, setFilterMrn] = useState("");
  const [pageArr, setPageArr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchByDropdown, setSearchByDropdown] = useState("name");
  const [searchByInput, setSearchByInput] = useState("");
  const [tempSampleList, setTempSampleList] = useState([]);
  const [sampleList, setSampleList] = useState(false);
  const [outsourceClinicList, setOutsourceClinicList] = useState([]);

  useEffect(() => {
    setTempSampleList(sampleList);
  }, [sampleList]);

  useEffect(() => {
    let te = [];
    if (sampleList && sampleList.length > 0) {
      te = sampleList.filter((row) => {
        if (
          row?.patient_first_name
            ?.toLowerCase()
            .includes(searchByInput?.toLowerCase()) &&
          searchByDropdown == "name"
        ) {
          return true;
        } else if (
          row?.patient_mobile
            ?.toLowerCase()
            .includes(searchByInput?.toLowerCase()) &&
          searchByDropdown == "mobile_no"
        ) {
          return true;
        } else if (
          `${row?.sample_id}`
            ?.toLowerCase()
            .includes(searchByInput?.toLowerCase()) &&
          searchByDropdown == "sample_id"
        ) {
          return true;
        } else if (
          `${row?.reservation_id}`
            ?.toLowerCase()
            .includes(searchByInput?.toLowerCase()) &&
          searchByDropdown == "reservation_id"
        ) {
          return true;
        } else if (
          `${row?.patient_report_mrn}`
            ?.toLowerCase()
            .includes(`${searchByInput}`?.toLowerCase()) &&
          searchByDropdown == "mrn"
        ) {
          return true;
        } else {
          return false;
        }
      });
    }
    setTempSampleList(te);
  }, [searchByDropdown, searchByInput]);

  const handleMultiOutsourceClinic = (event) => {
    let clinic = [];
    event.map((el) => {
      clinic.push(el);
    });
    setFilterOutsourcedClinic(clinic);
  };

  const [filterFlag, setFilterFlag] = useState(true);

  const [loading, setLoading] = useState(true);
  const [commentsModal, setCommentsModal] = useState(false);
  const [commentData, setCommentData] = useState();
  const [maxVisiblePages, setMaxVisiblePages] = useState(1);
  const cancelButtonRef = useRef(null);
  const [sample_id, setSampleid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sample_id: null,
    sample_is_active: false,
  });

  useEffect(() => {
    document.title = "Labib | Sample Processing";
    getOutsourceClinicList();
    setTimeout(() => {
      getAllSampleList(currentPage);
    }, 500);
  }, []);

  async function getOutsourceClinicList() {
    setLoading(true);
    try {
      const clinicsFromApi = await MasterServices.getClinicList();

      let newClinic = clinicsFromApi.data.data;
      let Clinics = newClinic.map((el) => {
        let obj = { ...el, label: el.mc_name, value: el.mc_id };
        return obj;
      });

      setOutsourceClinicList(Clinics);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const onFilter = () => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : "",
      filterTo: filterTo ? filterTo : "",
      filterStatus: filterStatus ? filterStatus : "",
      filterHos: filterHos ? filterHos : "",
      filterBkType: filterBkType ? filterBkType : "",
      filterOrg: filterOrg ? filterOrg : "",
      filterSampleId: filterSampleId ? filterSampleId : "",
      filterPatientMob: filterPatientMob ? filterPatientMob : "",
      filterPatientIdentification: filterPatientIdentification
        ? filterPatientIdentification
        : "",
      filterPatientName: filterPatientName ? filterPatientName : "",
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : "",
      filterTestType: filterTestType ? filterTestType : "",
      filterPatientId: filterPatientId ? filterPatientId : "",
      filterReservationId: filterReservationId ? filterReservationId : "",
      filterMrn: filterMrn ? filterMrn : "",
      filterOutsourcedClinic: filterOutsourcedClinic
        ? filterOutsourcedClinic
        : "",
    };
    getAllSampleListFilterList(queryString.stringify(filterInitialValues));
  };

  const onFilterClear = () => {
    setFilterFrom("");
    setFilterTo("");
    setFilterStatus("");
    setFilterHos("");
    setFilterBkType("");
    setFilterOrg("");
    setFilterSampleId("");
    setFilterPatientMob("");
    setFilterPatientIdentification("");
    setFilterPatientName("");
    setFilterPatientPassport("");
    setFilterTestType("");
    setFilterPatientId("");
    setFilterReservationId("");
    setFilterMrn("");
    setFilterOutsourcedClinic([]);
    getAllSampleList(currentPage);
  };

  const sampleListModification = (sampleList) => {
    // setLoading(true);
    let finalList = [];
    sampleList.map((test) => {
      let testObj = {};
      if (test.is_sub_test && test.all_subtest_lists?.length > 0) {
        for (let i = 0; i < test.all_subtest_lists.length; i++) {
          let subTestObj = { ...test };
          delete subTestObj.all_subtest_lists;
          subTestObj.subTestDetails = test.all_subtest_lists[i];

          subTestObj.ttp_id = subTestObj.subTestDetails.ttp_id;
          subTestObj.is_sub_rr = subTestObj.subTestDetails.is_sub_rr;
          subTestObj.sub_ref_range = subTestObj.subTestDetails.sub_ref_range;

          subTestObj.updated_fk_lab_ids =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].fk_lab_id
              : null;
          subTestObj.updated_fk_lab_analyzer_ids =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].fk_lab_analyzer_id
              : null;
          subTestObj.ssp_id =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].ssp_id
              : null;
          subTestObj.ssp_value =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].ssp_value
              : null;
          subTestObj.ssp_comment =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].ssp_comment
              : null;
          subTestObj.ssp_correction_value =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].ssp_correction_value
              : null;
          subTestObj.fk_sp_status_id =
            subTestObj.subTestDetails.test_result.length > 0
              ? subTestObj.subTestDetails.test_result[0].fk_status_id
              : subTestObj.fk_status_id;
          testObj = { ...subTestObj };

          finalList.push(testObj);
        }
      } else {
        testObj = test;
        finalList.push(testObj);
      }
    });

    var filtered = finalList.filter(function ({
      sp_gen_id,
      fk_reservation_id,
      fk_test_id,
      fk_patient_id,
      fk_res_patient_test_pack_id,
      fk_color_id,
      sample_id,
      test_id,
      patient_id,
      sp_status_id,
      ttp_id,
    }) {
      var key = `${sp_gen_id}${fk_reservation_id}${fk_test_id}${fk_patient_id}${fk_res_patient_test_pack_id}${fk_color_id}${sample_id}${test_id}${patient_id}${sp_status_id}${ttp_id}`;
      return !this.has(key) && this.add(key);
    },
    new Set());

    let i = 0;
    for (const samples of filtered) {
      samples.ValueType = "Not Changed";
      const index = filtered.findIndex(
        (obj) =>
          obj.fk_reservation_id === samples.fk_reservation_id &&
          obj.fk_patient_id === samples.fk_patient_id &&
          obj.fk_status_id === samples.fk_status_id
      );
      samples.buttonResultFlag = false;
      if (index === i && samples.buttonResultFlag == false) {
        samples.buttonResultFlag = true;
      }
      i++;
    }
    setSampleList(filtered);
    setLoading(false);
  };

  const getAllSampleListFilterList = (payload) => {
    setLoading(true);
    SampleServices.getAllSampleListForProcessingFilterList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample List Found!");
          setSampleList(data.data);
        } else {
          toast.success(data.message);
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  // Initial Load Sample List
  const getAllSampleList = (index) => {
    let payload = {
      page: index,
      pageSize: pageSize,
    };
    SampleServices.getAllSampleListForProcessingPagination(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            sampleListModification(data.data?.all_lists);
            setMaxVisiblePages(data.data?.maxVisiblePages);
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          } else {
            toast.error("SampleList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };

  const updateComment = () => {
    if (commentData) {
      setLoading(true);
      SampleServices.addSampleComment({
        sample_id: sample_id,
        sample_comment: commentData,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Comment Added Successfully!");

            setCommentsModal(false);
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
          setLoading(false);
        });
    } else {
      setCommentsModal(true);
      toast.error("Please Enter Comment");
    }
  };

  const onDeleteSample = (sample_id, sample_is_active) => {
    let Newsample_is_active = false;
    Newsample_is_active = sample_is_active == true ? false : true;
    let text = "Activated";
    if (Newsample_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    SampleServices.setSampleStatus({
      sample_id,
      sample_is_active: Newsample_is_active,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample " + text + " Successfully!");
          setConfirmationModal({ sample_id: null, status: false });
          getAllSampleList(currentPage);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg);
      });
    setConfirmationModal({ sample_id: null, status: false });
  };

  // Function to handle page change in the table component
  const handlePageChange = (index) => {
    setLoading(true);
    setCurrentPage(index);
    // Call the fetchUserData function with the updated page
    let payload = {
      page: index,
      pageSize: pageSize,
    };
    SampleServices.getAllSampleListForProcessingPagination(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            sampleListModification(data.data?.all_lists);
            setMaxVisiblePages(data.data?.maxVisiblePages);
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          } else {
            toast.error("SampleList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };

  return (
    <>
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.sample_is_active ? "Disable" : "Activate") +
          " this Sample?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() =>
          onDeleteSample(
            confirmationModal.sample_id,
            confirmationModal.sample_is_active
          )
        }
      />
      <div className="flex items-center justify-between my-2">
        <div className="flex">
          <input
            type="text"
            name="searchByInput"
            id="searchByInput"
            value={searchByInput}
            onChange={(e) => setSearchByInput(e.target.value)}
            placeholder="search by"
            className="block w-25 h-10 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
          />
          <select
            className="block w-25 ml-2 h-10 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
            name="searchByDropdown"
            id="searchByDropdown"
            value={searchByDropdown}
            onChange={(e) => setSearchByDropdown(e.target.value)}
          >
            <option value="name" selected>
              Name
            </option>
            <option value="mobile_no">Mobile No.</option>
            <option value="sample_id">Sample ID</option>
            <option value="reservation_id">Reservation ID</option>
            <option value="mrn">MRN</option>
          </select>
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setFilterFlag(filterFlag ? false : true);
            }}
            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1"
          >
            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </div>
              <div className="hidden sm:block">
                Filters {filterFlag ? "On" : "Off"}
              </div>
            </span>
          </button>
        </div>
      </div>
      {filterFlag ? (
        <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
          <div className="space-y-6 sm:space-y-5">
            {loading ? (
              <></>
            ) : (
              <div className="grid grid-cols-3 gap-3 mt-5 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0">
                <div className="basis-3/4"></div>
                <div className="basis-3/4"></div>
                <button
                  type="button"
                  className="px-1 py-1 font-bold text-right text-red-500 rounded hover:text-red-700"
                  onClick={() => setFilterFlag(filterFlag ? false : true)}
                >
                  X
                </button>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterFrom"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation From
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterFrom"
                      id="filterFrom"
                      value={filterFrom}
                      onChange={(e) => {
                        setFilterFrom(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation To
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterTo"
                      id="filterTo"
                      value={filterTo}
                      onChange={(e) => {
                        setFilterTo(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterStatus"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Status
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterStatus}
                      onInput={(e) => {
                        setFilterStatus(e.target.value);
                      }}
                      name="filterStatus"
                      id="filterStatus"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">In-Active</option>
                    </select>
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={filterPatientName}
                      onInput={(e) => {
                        setFilterPatientName(e.target.value);
                      }}
                      placeholder="Search By Name"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientName"
                      id="filterPatientName"
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientMob"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Mobile No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Mobile No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientMob"
                      id="filterPatientMob"
                      value={filterPatientMob}
                      onInput={(e) => {
                        setFilterPatientMob(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientIdentification"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Identification
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Identification"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientIdentification"
                      id="filterPatientIdentification"
                      value={filterPatientIdentification}
                      onInput={(e) => {
                        setFilterPatientIdentification(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterReservationId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Reservation No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterReservationId"
                      id="filterReservationId"
                      value={filterReservationId}
                      onInput={(e) => {
                        setFilterReservationId(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterMrn"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    MRN.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By MRN."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterMrn"
                      id="filterMrn"
                      value={filterMrn}
                      onInput={(e) => {
                        setFilterMrn(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterOutsourcedClinic"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Referral/Outsourced Clinic
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      isMulti
                      name="filterOutsourcedClinic"
                      id="filterOutsourcedClinic"
                      value={filterOutsourcedClinic}
                      onChange={(e) => handleMultiOutsourceClinic(e)}
                      options={outsourceClinicList}
                      className="basic-multi-select"
                      classNamePrefix="Select Test"
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <button
                    type="button"
                    className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                    onClick={() => {
                      onFilter();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                    onClick={() => {
                      onFilterClear();
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <div>
          {loading ? (
            <FallingLinesLoader />
          ) 
          : 
          tempSampleList.length > 0 ? (
            <Table
              maxVisiblePages={maxVisiblePages}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              pageArr={pageArr}
              columns={crm_sample__processing_columns({tempSampleList})}
              data={tempSampleList}
              isSearchBar={false}
            />
          ) : 
          // (
          //   <FallingLinesLoader />
          // )
          <></>
          }
        </div>
      </div>

      <Transition.Root show={commentsModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setCommentsModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 booking-quotation-table-w sm:p-6">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="pt-5 space-y-6 sm:space-y-5 sm:pt-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Add Comment
                            </h3>
                          </div>
                          <div className="flex items-center justify-between">
                            <textarea
                              type="text"
                              value={commentData}
                              className="block border-gray-300 rounded-md shadow-sm input-w-class focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              onInput={(e) => setCommentData(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setCommentsModal(false)}
                            type="button"
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              updateComment();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default CRMSampleProcessingTab;
