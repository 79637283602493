import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { userSchema } from "../../../schemas";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { discount_otp_receivers_columns, user_columns } from "../../../components/tables/tableheader";
import {
    getAllUsers,
    updateUser,
} from "../../../redux/user/actions";

import { getAllRoles } from "../../../redux/role/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getLoginUserInfo } from "../../../redux/userInfo/actions";


function DiscountOTPReceivers() {
    const pages = [{ title: "User Access List", href: "/user-access-list" }];
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [user_id, setUserid] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [mobileNumber, setMobileNumber] = useState('');
    const [userMobileNumber, setUserMobileNumber] = useState('');
    const [mobileNumberValid, setMobileNumberValid] = useState(true);
    const [mobileCountryCode, setMobileCountryCode] = useState('')
    const [initialRender, setInitialRender] = useState(true);

    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        user_id: null,
    });
    const [approvePOConfirmationModal, setApprovePOConfirmationModal] = useState({
        status: false,
        user_id: null,
    })
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [globalFilter, setGlobalFilter] = useState("");
    const {
        user: { loading: userLoading, list: userList, pagination: pagination },
    } = useSelector((state) => state);


    //Mobile number validation in patient
    useEffect(() => {
        if (!initialRender) {
            // console.log(mobileNumber.toString())
            if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
                const phoneNumber = parsePhoneNumber(mobileNumber)
                console.log(phoneNumber)
                if (phoneNumber) {
                    setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
                    setUserMobileNumber(phoneNumber.nationalNumber);
                }
                setMobileNumberValid(true);
            } else {
                setMobileNumberValid(false)
            }
        } else {
            setInitialRender(false)
        }
    }, [mobileNumber])

    const onMobileNumberChange = (value) => {
        if (value) {
            setMobileNumber(value)
        }
    }

    useEffect(() => {
        document.title = "Labib | Users";
        // setIsLoading(true)
        let payload = {
            search: "All",
            search_text: globalFilter ? globalFilter : "",
            page_size: pagination?.page_size ? pagination.page_size : 20,
            page_no: globalFilter ? 1 : pagination?.page_no,
          };
        dispatch(getAllUsers(payload));
        dispatch(getAllRoles());
        setIsLoading(false)
    }, []);

    const userSchema = Yup.object({
        user_first_name: Yup.string()
            .min(3)
            .max(25)
            .required("Please Enter the First Name"),
        user_last_name: Yup.string()
            .min(2)
            .max(25)
            .required("Please Enter the Last Name"),
        user_email: Yup.string().email().required("Please Enter the Email address"),
        // user_mobile: Yup.string()
        // 	.min(10)
        // 	.max(10)
        // 	.required("Please Enter the Mobile Number"),
        user_password: user_id ? Yup.string() : Yup.string().min(6).required("Please Enter the Password"),
        user_role: Yup.string().required("Please Enter the Role"),
        mc_id: Yup.string().when('user_role', {
            is: '13',
            then: Yup.string().required('Please Select Clinic'),
            otherwise: Yup.string().nullable()
        }),
        /* mc_id: Yup.string().when('user_role', {
            is: (value) => value === 13,
            then: Yup.string().required('Please Select Clinic'),
            otherwise: Yup.string()
        }).nullable() */
    });

    const initialValues = {
        user_id: "",
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_mobile: "",
        user_location_id: "",
        user_role: "",
        user_password: "",
        // conf_password: "",
        user_status: true,
        loc_id: "",
        hos_id: "",
        user_signature: "",
        mc_id: ""
    };

    const [formUser, setFormUser] = useState(initialValues);

    const handleDrawer = (type, id, obj) => {

        setMobileNumberValid(true)
        if ((type === "edit", id)) {
            setUserid(id);
            let mergedPhone = `+${obj.user_dial_code}${obj.user_mobile}`;
            console.log("obj=>", obj, isValidPhoneNumber(mergedPhone), mergedPhone);
            if (obj.user_mobile && obj.user_dial_code && isValidPhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)) {
                // console.log(`+${}${obj.user_mobile}`)
                const phoneNumber = parsePhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)
                console.log(phoneNumber)
                if (phoneNumber) {
                    setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
                    setMobileNumber(phoneNumber.number);
                    console.log(mobileCountryCode, userMobileNumber)
                }
            }
            const initialValues = {
                user_id: obj.user_id,
                user_first_name: obj.user_first_name,
                user_last_name: obj.user_last_name,
                user_email: obj.user_email,
                user_mobile: obj.user_mobile,
                user_location_id: obj.user_location_id,
                user_role: obj.user_role_id,
                user_status: obj.user_is_active,
                loc_id: obj.fk_hsp_loc_id,
                hos_id: obj.fk_hos_id,
                user_signature: obj.user_signature,
                mc_id: obj.fk_ot_clinic_id ? obj.fk_ot_clinic_id : ""
            };
            /* if(obj.fk_ot_clinic_id != null){
                initialValues.mc_id = obj.fk_ot_clinic_id;
                setOutSourcingClinicId(obj.fk_ot_clinic_id);
                console.log("obj.fk_ot_clinic_id =>", initialValues);
            } */
            console.log("handleDrawer initialValues=>", initialValues);
            // setSignFilePath(obj.user_signature)
            setFormUser(initialValues);
        } else {
            setMobileNumber("");
            // setSignFilePath("")
            setUserid("");
            setFormUser(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };

    const onDeleteOpen = (user_id, user_is_discount_otp_receiver) => {
        setConfirmationModal({ user_id, user_is_discount_otp_receiver, status: true });
    };

    const handlePageChange = (page) => {
        let payload = {
          search: "All",
          isAll: 1,
          isSelectedColumn: 0,
          search_text: globalFilter ? globalFilter : "",
          page_size: pagination?.page_size ? pagination.page_size : 20,
          page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
        };
        dispatch(getAllUsers(payload));
      };

    const onChangeOTPReceivingStatus = async (user_id, user_is_discount_otp_receiver) => {
        let Newuser_is_discount_otp_receiver = false;
        Newuser_is_discount_otp_receiver = user_is_discount_otp_receiver == true ? false : true;
        let text = "Activated";
        if (user_is_discount_otp_receiver == true) {
            text = "Activated";
        } else {
            text = "In-Activated";
        }
        setIsLoading(true)
        
        console.log("inside discount otp stat change", Newuser_is_discount_otp_receiver)
        MasterServices.setMUserOTPReceivingStatus({
            user_id,
            user_is_discount_otp_receiver: Newuser_is_discount_otp_receiver
        })
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("User " + text + " Successfully!")
                    setConfirmationModal({ user_id: null, status: false });
                    let payload = {
                        search: "All",
                        isAll: 1,
                        isSelectedColumn: 0,
                        search_text: globalFilter ? globalFilter : "",
                        page_size: pagination?.page_size ? pagination.page_size : 20,
                        page_no: globalFilter ? 1 : pagination?.page_no,
                      };
                    dispatch(getAllUsers(payload))
                    navigate("/user-access-list");
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setIsLoading(false)
            })
            .catch((error) => {
                const msg = error?.data?.message;
                toast.error(msg)
                setIsLoading(false)
            });
    };

    const onApprovePoOpen = (user_id, user_can_approve_po) => {
        setApprovePOConfirmationModal({ user_id, user_can_approve_po, status: true });
    }

    const onChangeApprovePOStatus = async (user_id, user_can_approve_po) => {
        let Newuser_can_approve_po = false;
        Newuser_can_approve_po = user_can_approve_po == true ? false : true;
        let text = "Activated";
        if (user_can_approve_po == true) {
            text = "Activated";
        } else {
            text = "In-Activated";
        }
        setIsLoading(true)
        
        MasterServices.setMUserApprovePOStatus({
            user_id,
            user_can_approve_po: Newuser_can_approve_po
        })
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("User " + text + " Successfully!")
                    setApprovePOConfirmationModal({ user_id: null, status: false });
                    let payload = {
                        search: "All",
                        isAll: 1,
                        isSelectedColumn: 0,
                        search_text: globalFilter ? globalFilter : "",
                        page_size: pagination?.page_size ? pagination.page_size : 20,
                        page_no: globalFilter ? 1 : pagination?.page_no,
                      };
                    dispatch(getAllUsers(payload))
                    dispatch(getLoginUserInfo())
                    navigate("/user-access-list");
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setIsLoading(false)
            })
            .catch((error) => {
                const msg = error?.data?.message;
                toast.error(msg)
                setIsLoading(false)
            });

           
    };


    return (
        isLoading ? <FallingLinesLoader /> : (
            <div className="px-4 sm:px-6 lg:px-8">

                <ConfirmationModal
                    title={"Are you sure you want to " + (confirmationModal.user_is_discount_otp_receiver ? "Disable " : "Activate") + " receiving OTP for this User?"}
                    // confirmationButtonText={confirmationModal.user_is_active ? "Disable" : "Activate"}
                    confirmationButtonText="Yes"
                    // description={"Do you really want to " + (confirmationModal.user_is_active ? "Disable" : "Activate") + " User"}
                    open={confirmationModal.status}
                    onDelete={() => onChangeOTPReceivingStatus(confirmationModal.user_id, confirmationModal.user_is_discount_otp_receiver)}
                    setOpen={setConfirmationModal}
                />

                <ConfirmationModal
                    title={"Are you sure you want to " + (approvePOConfirmationModal.user_can_approve_po ? "Disable " : "Activate") + " approve purchase order for this User?"}
                    confirmationButtonText="Yes"
                    open={approvePOConfirmationModal.status}
                    onDelete={() => onChangeApprovePOStatus(approvePOConfirmationModal.user_id, approvePOConfirmationModal.user_can_approve_po)}
                    setOpen={setApprovePOConfirmationModal}
                />

                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">User Access List</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the users access.</p>
                </div>


                {userLoading ? (
                    <FallingLinesLoader />
                ) : (
                    <Table
                        columns={discount_otp_receivers_columns({ onDeleteOpen, handleDrawer, onApprovePoOpen })}
                        data={userList}
                        pagination={pagination}
                        onPageChange={handlePageChange}
                        onGlobalFilterChange={setGlobalFilter}
                    />
                )}

            </div>)
    );
}
export default DiscountOTPReceivers;