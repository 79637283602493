import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from "formik";
import Breadcrumb from "../../../components/Breadcrumb";
import { userInfoSchema } from '../../../schemas';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import { FileUploader } from 'react-drag-drop-files';
import { singleUploadFiles } from '../../../helper/multiUploadHelper';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { getLoginUserInfo } from '../../../redux/userInfo/actions';
import { useDispatch } from 'react-redux';

const fileTypes = ["JPEG", "PNG", "GIF"];

const Profile = () => {
    const pages = [{ title: "Profile", href: "/profile" }];
    const [locationList, setLocationList] = useState([]);
    const profileImageRef = useRef();
    const dispatch = useDispatch();
    const [profileImage, setProfileImage] = useState();
    const [loading, setLoading] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);

    const initialValues = {
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_dial_code: 966,
        user_mobile: "",
        // user_signature: "",
        // user_role_id: "",
        user_profile_image: "",
    };

    const updateUserInfo = async (payload) => {
        setUpdateLoader(true);
        MasterServices.updateUserInfo(payload)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    let user = data.data;
                    console.log(data.data);
                    dispatch(getLoginUserInfo('All'))
                    setUserInfo({
                        user_first_name: user.user_first_name,
                        user_last_name: user.user_last_name,
                        user_email: user.user_email,
                        user_dial_code: user.user_dial_code,
                        user_mobile: user.user_mobile,
                        fk_hsp_loc_id: user.fk_hsp_loc_id,
                        role_name: user.m_role_relation.role_name_en,
                        user_profile_image: user.user_profile_image,
                    })
                    setProfileImage(user.user_profile_image)
                    toast.success(data.message)
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setUpdateLoader(false);
            })
            .catch((error) => {
                const msg = error.data.message;
                setUpdateLoader(false);
                toast.error(msg)
            });
    }

    const getUserInfo = async () => {
        setLoading(true)
        MasterServices.getUserProfile()
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    let user = data.data;
                    console.log(data.data);
                    setUserInfo({
                        user_first_name: user.user_first_name,
                        user_last_name: user.user_last_name,
                        user_email: user.user_email,
                        user_dial_code: user.user_dial_code || 966,
                        user_mobile: user.user_mobile,
                        fk_hsp_loc_id: user.fk_hsp_loc_id,
                        role_name: user.m_role_relation.role_name_en,
                        user_profile_image: user.user_profile_image,
                    })
                    setProfileImage(user.user_profile_image)
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setLoading(false);
            })
            .catch((error) => {
                const msg = error.data.message;
                setLoading(false);
                toast.error(msg)
            });
    }

    const getHospitalLocationList = async () => {
        MasterServices.getLocationList()
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    let list = data.data;
                    setLocationList(list);
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = error.data.message;
                toast.error(msg)
            });
    }

    useEffect(() => {
        getUserInfo();
        getHospitalLocationList();
    }, [])

    const onChangeClick = () => {
        profileImageRef.current.click();
    }

    const handleFileChange = async (field, file) => {
        const response = await singleUploadFiles(file, 'users');
        if (field == 'profileImage') {
            setProfileImage(response.data);
        }
    };

    const [userInfo, setUserInfo] = useState(initialValues);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: userInfo,
            validationSchema: userInfoSchema,
            onSubmit: async (values, action) => {
                console.log("Submit Clicked", values);
                delete values.role_name;
                values.user_profile_image = profileImage;
                await updateUserInfo(values);
            },
        });

    return (
        <>
            {loading ? <FallingLinesLoader /> :
                <div>
                    <Breadcrumb pages={pages} />
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                        className="space-y-8 divide-y divide-gray-200">
                        <div className='flex mt-14'>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 basis-1/4">
                                <div className="flex items-center flex-col">
                                    <span className="h-28 w-28 overflow-hidden rounded-full bg-gray-100">
                                        {!profileImage ?
                                            (
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            ) :
                                            (
                                                <img src={profileImage} alt='profile-image' />
                                            )
                                        }
                                    </span>
                                    <input id='user_profile_image' onChange={e => handleFileChange('profileImage', e.target.files[0])} ref={profileImageRef} type='file' hidden />
                                    <div className='flex'>
                                        <button
                                            type="button"
                                            onClick={onChangeClick}
                                            className="mt-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 m-1"
                                        >
                                            Change
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setProfileImage('')}
                                            className="mt-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 m-1"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    <span className="mt-1 max-w-2xl text-sm text-gray-500">upload: jpg,png,gif</span>
                                </div>
                            </div>
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 basis-3/4 sm:px-6 md:px-8">
                                <div className="space-y-6 sm:space-y-5">
                                    <div>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                            This information will be displayed publicly so be careful what you share.
                                        </p>
                                    </div>

                                    <div className="space-y-6 sm:space-y-5">
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="user_first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                First name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="text"
                                                    name="user_first_name"
                                                    id="user_first_name"
                                                    value={values.user_first_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="given-name"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                />
                                                {errors.user_first_name &&
                                                    touched.user_first_name ? (
                                                    <p className="text-red-600 text-sm">
                                                        {errors.user_first_name}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="user_last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Last name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="text"
                                                    name="user_last_name"
                                                    id="user_last_name"
                                                    value={values.user_last_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="family-name"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                />
                                                {errors.user_last_name &&
                                                    touched.user_last_name ? (
                                                    <p className="text-red-600 text-sm">
                                                        {errors.user_last_name}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="user_email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Email address
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    disabled
                                                    id="user_email"
                                                    name="user_email"
                                                    type="email"
                                                    value={values.user_email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="email"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm bg-slate-100"
                                                />
                                                {errors.user_email &&
                                                    touched.user_email ? (
                                                    <p className="text-red-600 text-sm">
                                                        {errors.user_email}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>


                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="role" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Role
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    type="text"
                                                    name="role_name"
                                                    id="role_name"
                                                    autoComplete="role_name"
                                                    value={values.role_name}
                                                    disabled
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm bg-slate-100"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="user_mobile" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Mobile Number
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    disabled
                                                    type="number"
                                                    name="user_mobile"
                                                    id="user_mobile"
                                                    value={values.user_mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="mobile"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm bg-slate-100"
                                                />
                                                {errors.user_mobile &&
                                                    touched.user_mobile ? (
                                                    <p className="text-red-600 text-sm">
                                                        {errors.user_mobile}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Location
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <select
                                                    id="fk_hsp_loc_id"
                                                    name="fk_hsp_loc_id"
                                                    autoComplete="fk_hsp_loc_id"
                                                    value={values.fk_hsp_loc_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm bg-slate-100"
                                                >
                                                    <option value="">please select location</option>
                                                    {locationList && locationList.map(loc => (
                                                        <option value={loc.loc_id}>{loc.loc_name_en}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    disabled={updateLoader}
                                    className={`ml-3 inline-flex justify-center rounded-md border border-transparent ${updateLoader ? 'bg-gray-600' : 'bg-cyan-600'} py-2 px-4 text-sm font-medium text-white shadow-sm hover:${updateLoader ? 'bg-gray-700' : 'bg-cyan-700'} focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default Profile