import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { Formik } from "formik";
import { patientDataSchemaOC, userPatientSchema } from "../../../schemas";
import PhoneInputWithCountry, {
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { toast } from "react-toast";
import { getAllGenders } from "../../../redux/gender/actions";
import ReservationServices from "../../../ApiServices/ReservationServices";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";

const relationshipList = [
  {
    label: "Mother",
    value: "Mother",
  },
  {
    label: "Father",
    value: "Father",
  },
  {
    label: "Son",
    value: "Son",
  },
  {
    label: "Wife",
    value: "Wife",
  },
  {
    label: "Daughter",
    value: "Daughter",
  },
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Sister",
    value: "Sister",
  },
];
const identityTypeList = [
  "National ID",
  "Iqama",
  "BorderNo",
  "Gulf ID",
  "Passport No",
];
const reasonOfTestingList = [
  "Diagnosis",
  "Travelling To Outside KSA",
  "Public Quarantine (Hotel)",
  "Home Quarantine",
  "covid test",
];

function testStatusToggle(...testStatus) {
  return testStatus.filter(Boolean).join(" ");
}

export default function EditPatient({
  patient_id,
  userType,
  setSelectedPatient,
  reservation_id,
  openPatient,
  cancelButtonRef,
  setOpenPatient,
  closeModal,
  addUserPatient,
  user_id,
  filteredCountryList,
  ocPatient,
  isInsu,
  setIsInsu,
  membership,
}) {
  const {
    gender: { list: genderList },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState(false);
  const [userId, setUserId] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const currentDate = moment().format("YYYY-MM-DD");
  const [invalidDate, setInvalidDate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGenders());
  }, []);

  useEffect(() => {
    if (patient_id) {
      getPatientDetails(patient_id);
    }
    return setSelectedPatient(false);
  }, [patient_id]);
  //Mobile number validation in patient
  useEffect(() => {
    if (!initialRender) {
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
        const phoneNumber = parsePhoneNumber(mobileNumber);
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setUserMobileNumber(phoneNumber.nationalNumber);
        }
        setMobileNumberValid(true);
      } else {
        setMobileNumberValid(false);
      }
    } else {
      setInitialRender(false);
    }
  }, [mobileNumber]);

  const onMobileNumberChange = (value) => {
    if (value) {
      setMobileNumber(value);
    }
  };
  // get patient details
  const getPatientDetails = (p_id) => {
    if (p_id) {
      setIsLoading(true);
      ReservationServices.getSinglePatient(p_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data) {
              let {
                patient_first_name: user_first_name,
                patient_id_type: user_id_type,
                fk_country_id,
                patient_id_no: user_id_no,
                patient_passport_no: user_passport_no,
                patient_mobile,
                patient_dial_code,
                patient_email: user_email,
                fk_gender_id,
                patient_dob: user_dob,
                patient_reasion_test: user_reasion_test,
                relationship,
                fk_user_id: user_id,
                patient_id,
                insu_membership_id,
                patient_age: user_age,
              } = data.data;

              setUserId(user_id);

              // setMobileNumber("+"+patient_dial_code+(patient_mobile+""))
              setMobileNumber(`+${patient_dial_code}${patient_mobile}`);

              setPatientDetails({
                user_first_name,
                fk_country_id,
                user_id_type,
                user_id_no,
                user_passport_no,
                user_email,
                fk_gender_id,
                user_dob,
                user_reasion_test,
                relationship,
                patient_id,
                insu_membership_id,
                user_age,
              });
            } else {
              toast.error("Fatal Error Please Contact Admin");
            }
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          const msg = "getPatientDetails catch Error"; //error.response.data.message;
          toast.error(msg);
          setIsLoading(false);
        });
    }
  };

  // Populate it as initialValues

  // Store the modified values

  // If Patient update the m_patient, else update both u_user and m_patient

  return (
    <Transition.Root show={openPatient} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpenPatient(false);
          closeModal();
        }}
      >
        <Transition.Child
          afterLeave={() => {
            closeModal();
          }}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Formik
          enableReinitialize={true}
          onSubmit={(values, action) => {
            if (!mobileNumberValid || mobileNumber == "") {
              setMobileNumberValid(false);
              return false;
            }
            // if (!(new Date(values.user_dob) > (new Date('1949-01-01'))) || !(new Date(values.user_dob) <= (new Date(currentDate)))) {
            //   console.log('Invalid Date')
            //   setInvalidDate(true)
            //   return false
            // } else {
            //   setInvalidDate(false)
            // }

            let addObj = {
              user_first_name: values.user_first_name,
              user_email: values.user_email || "",
              user_dob: values.user_dob,
              user_id_type: values.user_id_type,
              user_id_no: values.user_id_no,
              user_passport_no: values.user_passport_no || "",
              user_reasion_test: values.user_reasion_test,
              fk_gender_id: parseInt(values.fk_gender_id),
              fk_country_id: parseInt(values.fk_country_id),
              relationship: values.relationship,
              reservation_id,

              // user_mobile: userMobileNumber,
              // patient_dial_code: mobileCountryCode,

              user_mobile: userMobileNumber,
              patient_dial_code: mobileCountryCode,

              // fk_questionnaire_id: parseInt(values.fk_questionnaire_id),
              userType: userType,
              // Type: "Add",
            };
            if (userType === "User") {
              addObj.relationship = "Self";
              addObj.user_id = user_id;
              addObj.patient_id = patientDetails.patient_id;
            } else if (userType === "Patient") {
              addObj.user_id = userId;
              addObj.patient_id = patientDetails.patient_id;
            }
            addUserPatient(addObj, action);
            setPatientDetails(false);
            closeModal();
          }}
          initialValues={patientDetails}
          validationSchema={ocPatient ? patientDataSchemaOC : userPatientSchema}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            setValues,
            values,
            touched,
            isValid,
            isSubmitting,
            errors,
            handleReset,
          }) => (
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                    <div>
                      {ocPatient ? (
                        <form
                          noValidate
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200"
                        >
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                              <div className="flex">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                  Edit {userType} Account
                                </h3>
                              </div>
                              <div className="space-y-6 sm:space-y-5">
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Full Name *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_first_name"
                                        id="user_first_name"
                                        value={values.user_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Full Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_first_name &&
                                      touched.user_first_name ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_first_name}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="natinality"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Nationality *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      {filteredCountryList &&
                                        filteredCountryList.length > 0 && (
                                          <select
                                            value={values.fk_country_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                            name="fk_country_id"
                                            id="fk_country_id"
                                          >
                                            <option selected value="">
                                              Select Nationality
                                            </option>
                                            {filteredCountryList &&
                                              filteredCountryList.length > 0 &&
                                              filteredCountryList.map(
                                                (country, i) => (
                                                  <option
                                                    key={i}
                                                    value={country.country_id}
                                                  >
                                                    {country.country_name}
                                                  </option>
                                                )
                                              )}
                                          </select>
                                        )}
                                      {errors.fk_country_id &&
                                      touched.fk_country_id ? (
                                        <p className="text-red-600 text-xs">
                                          {/* {errors.fk_country_id} */}
                                          Please Select Nationality
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="gender"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Gender *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.fk_gender_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        name="fk_gender_id"
                                        id="fk_gender_id"
                                      >
                                        <option>Select Gender</option>
                                        {genderList.map((gender, i) => (
                                          <option
                                            key={i}
                                            value={gender.gender_id}
                                          >
                                            {gender.gender_name_en}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.fk_gender_id &&
                                      touched.fk_gender_id ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.fk_gender_id}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="patient-age"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Age *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="number"
                                        name="user_age"
                                        id="user_age"
                                        min={0}
                                        max={110}
                                        value={values.user_age}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Age`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_age && touched.user_age ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_age}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="">
                                    <label
                                      htmlFor="birth-date"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Date of Birth
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="date"
                                        name="user_dob"
                                        id="user_dob"
                                        autoComplete="off"
                                        max={currentDate}
                                        min={"1949-01-01"}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_dob && touched.user_dob ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_dob}
                                        </p>
                                      ) : null}
                                      {invalidDate && (
                                        <p className="text-red-600 text-xs">
                                          Invalid DOB
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="insu_membership_id"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Membership Id *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.insu_membership_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="insu_membership_id"
                                        id="insu_membership_id"
                                        autoComplete="off"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.insu_membership_id &&
                                      touched.insu_membership_id ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.insu_membership_id}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="birth-date"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      BCC *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <Switch
                                        checked={isInsu}
                                        onChange={() =>
                                          setIsInsu(
                                            isInsu == false ? true : false
                                          )
                                        }
                                        name="is_insu"
                                        className={testStatusToggle(
                                          isInsu
                                            ? "bg-cyan-600"
                                            : "bg-gray-200",
                                          "mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                        )}
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={testStatusToggle(
                                            isInsu
                                              ? "translate-x-5"
                                              : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                          )}
                                        />
                                      </Switch>
                                      {errors.insu_bcc && touched.insu_bcc ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.insu_bcc}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="mobile"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Mobile No. *
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <PhoneInputWithCountry
                                        defaultCountry="SA"
                                        value={mobileNumber}
                                        placeholder="Enter phone number"
                                        onChange={(e) => {
                                          setMobileNumber(e);
                                          values.insu_user_mobile = e;
                                        }}
                                      />
                                      {/* {
                                            errors.insu_user_mobile || insuMobileNumberValid ? (
                                              <p className="text-red-600 text-xs">{errors.insu_user_mobile}</p>
                                            ) : null
                                          } */}

                                      {!mobileNumberValid ? (
                                        <p className="text-red-600 text-xs">
                                          Mobile Number is invalid.
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="patient-file-no"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      File No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_file_no"
                                        id="user_file_no"
                                        value={values.user_file_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter File No.`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_file_no &&
                                      touched.user_file_no ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_file_no}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                onClick={() => {
                                  setOpenPatient(false);
                                  closeModal();
                                }}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <form
                          noValidate
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200"
                        >
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                              <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                  Edit {userType} Account
                                </h3>
                              </div>
                              <div className="space-y-6 sm:space-y-5">
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Full Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_first_name"
                                        id="user_first_name"
                                        value={values.user_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Full Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_first_name &&
                                      touched.user_first_name ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_first_name}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      {userType} Last Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_last_name"
                                        id="user_last_name"
                                        value={values.user_last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter ${userType} Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_last_name && touched.user_last_name ? (
                                          <p className="text-red-600 text-xs">{errors.user_last_name}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div> */}

                                  {userType === "Patient" ? (
                                    <div className="">
                                      <label
                                        htmlFor="patient-name"
                                        className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Relationship
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          value={values.relationship}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                          name="relationship"
                                          id="relationship"
                                        >
                                          <option value="">
                                            Select Relationship
                                          </option>
                                          {relationshipList.map(
                                            (relationship_ele, i) => (
                                              <option
                                                key={i}
                                                value={relationship_ele.value}
                                              >
                                                {relationship_ele.value}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {errors.relationship &&
                                        touched.relationship ? (
                                          <p className="text-red-600 text-xs">
                                            {errors.relationship}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="">
                                    <label
                                      htmlFor="natinality"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Nationality
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      {filteredCountryList &&
                                        filteredCountryList.length > 0 && (
                                          <select
                                            value={values.fk_country_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                            name="fk_country_id"
                                            id="fk_country_id"
                                          >
                                            <option selected value="">
                                              Select Nationality
                                            </option>
                                            {filteredCountryList &&
                                              filteredCountryList.length > 0 &&
                                              filteredCountryList.map(
                                                (country, i) => (
                                                  <option
                                                    key={i}
                                                    value={country.country_id}
                                                  >
                                                    {country.country_name}
                                                  </option>
                                                )
                                              )}
                                          </select>
                                        )}
                                      {errors.fk_country_id &&
                                      touched.fk_country_id ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.fk_country_id}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="identity-type"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Identity Type
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex">
                                        <select
                                          value={values.user_id_type}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          id="user_id_type"
                                          name="user_id_type"
                                          autoComplete="off"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs mr-3"
                                        >
                                          <option value="">
                                            Select ID Type
                                          </option>
                                          {values.fk_country_id == 203
                                            ? identityTypeList.map(
                                                (identity, i) => (
                                                  <option
                                                    key={i}
                                                    value={identity}
                                                  >
                                                    {identity}
                                                  </option>
                                                )
                                              )
                                            : identityTypeList
                                                .filter(
                                                  (v) => v !== "National ID"
                                                )
                                                .map((identity, i) => (
                                                  <option
                                                    key={i}
                                                    value={identity}
                                                  >
                                                    {identity}
                                                  </option>
                                                ))}
                                        </select>
                                        <input
                                          type="text"
                                          disabled={
                                            values.user_id_type == ""
                                              ? true
                                              : false
                                          }
                                          value={values.user_id_no}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name="user_id_no"
                                          id="user_id_no"
                                          autoComplete="off"
                                          placeholder="Please Enter ID No"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        />
                                      </div>
                                      {errors.user_id_type &&
                                      touched.user_id_type ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_id_type}
                                        </p>
                                      ) : null}
                                      {errors.user_id_no &&
                                      touched.user_id_no ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_id_no}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="passport"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Passport No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_passport_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="user_passport_no"
                                        id="user_passport_no"
                                        autoComplete="off"
                                        placeholder="Please Enter Passport No (optional)"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {/* {
                                      errors.user_passport_no && touched.user_passport_no ? (
                                        <p className="text-red-600 text-xs">{errors.user_passport_no}</p>
                                      ) : null
                                    } */}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="mobile"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Mobile No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <PhoneInputWithCountry
                                        defaultCountry="SA"
                                        value={mobileNumber}
                                        placeholder="Enter phone number"
                                        onChange={(e) => {
                                          setMobileNumber(e);
                                          // console.log(e, values.user_mobile, errors.user_mobile)
                                          values.user_mobile = e;
                                        }}
                                      />
                                      {/* {
                                        errors.user_mobile || mobileNumberValid ? (
                                          <p className="text-red-600 text-xs">{errors.user_mobile}</p>
                                        ) : null
                                      }  */}
                                      {!mobileNumberValid ? (
                                        <p className="text-red-600 text-xs">
                                          Mobile Number is invalid.
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="email"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Email address
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="user_email"
                                        name="user_email"
                                        type="email"
                                        autoComplete="off"
                                        placeholder="Please Enter Email ID (optional)"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {/* {
                                      errors.user_email && touched.user_email ? (
                                        <p className="text-red-600 text-xs">{errors.user_email}</p>
                                      ) : null
                                    } */}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="gender"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Gender
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.fk_gender_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        name="fk_gender_id"
                                        id="fk_gender_id"
                                      >
                                        <option>Select Gender</option>
                                        {genderList.map((gender, i) => (
                                          <option
                                            key={i}
                                            value={gender.gender_id}
                                          >
                                            {gender.gender_name_en}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.fk_gender_id &&
                                      touched.fk_gender_id ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.fk_gender_id}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="birth-date"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Date of Birth
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="date"
                                        name="user_dob"
                                        id="user_dob"
                                        autoComplete="off"
                                        max={currentDate}
                                        min={"1949-01-01"}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {errors.user_dob && touched.user_dob ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_dob}
                                        </p>
                                      ) : null}
                                      {invalidDate && (
                                        <p className="text-red-600 text-xs">
                                          Invalid DOB
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="">
                                    <label
                                      htmlFor="testing"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Reason of testing
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.user_reasion_test}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="user_reasion_test"
                                        name="user_reasion_test"
                                        autoComplete="off"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      >
                                        <option>
                                          Select Reason of testing
                                        </option>
                                        {reasonOfTestingList.map(
                                          (reasonOfTesting, i) => (
                                            <option
                                              key={i}
                                              value={reasonOfTesting}
                                            >
                                              {reasonOfTesting}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {errors.user_reasion_test &&
                                      touched.user_reasion_test ? (
                                        <p className="text-red-600 text-xs">
                                          {errors.user_reasion_test}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                onClick={() => {
                                  setOpenPatient(false);
                                  closeModal();
                                  setPatientDetails(false);
                                }}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                onClick={() => {
                                  values.user_mobile = mobileNumber;
                                  if (mobileNumber == "") {
                                    setMobileNumberValid(false);
                                  } else {
                                    // setOpenPatient(false);
                                  }
                                  // console.log("values", {...values, user_mobile: mobileNumber.user_mobile, patient_dial_code:mobileNumber.patient_dial_code, userType })
                                }}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          )}
        </Formik>
      </Dialog>
    </Transition.Root>
  );
}
