import React, { useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import { patient_list_columns } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from "react-toast";

function PatientList() {
	const pages = [{ title: "Patient List", href: "/patient-list", module_id: 2 }];
	const [isLoading, setIsLoading] = useState(true);
	const [userPatientList, setPatientUserList] = useState([]);
	const [pagination, setPagination] = useState({ page_size: 20, page_no: 1 });
	const [globalFilter, setGlobalFilter] = useState("");

	useEffect(() => {
		document.title = "Labib | Patients";
		setIsLoading(true)
		getAllPatientUserList(1);
		// setIsLoading(false)
	}, []);

	const getAllPatientUserList = (page) => {
		let payload = {
			search: "All",
			isAll: 1,
			isSelectedColumn: 0,
			search_text: globalFilter ? globalFilter : "",
			page_size: pagination?.page_size ? pagination.page_size : 10,
			page_no: globalFilter ? 1 : page > 0 ? page : pagination.page_no,
		};
		ReservationServices.getAllUserList(payload)
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					setIsLoading(false);
					if (data?.data?.length > 0) {
						console.log("data.data", data.meta);
						setPagination(data.meta)
						setPatientUserList(data.data);
					} else {
						setIsLoading(false);
						toast.error("PatientList Not Found")
					}
				} else {
					setIsLoading(false);
					toast.error("Fatal Error Please Contact Admin")
				}
			})
			.catch((error) => {
				setIsLoading(false);
				const msg = "getAllPatientUserList catch Error";
				toast.error(msg);
			});
	}

	const onDeleteOpen = () => {

	}
	useEffect(() => {
		getAllPatientUserList(pagination.page_no);
	}, [globalFilter]);

	const handleDrawer = () => {

	}
	const handlePageChange = (page) => {
		getAllPatientUserList(page)
	};

	return (
		// isLoading ? <FallingLinesLoader /> : (
		<div className="px-4 sm:px-6 lg:px-8">

			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Patient List</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Patients.</p>
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={patient_list_columns({ onDeleteOpen, handleDrawer })}
					data={userPatientList}
					pagination={pagination}
					onPageChange={handlePageChange}
					onGlobalFilterChange={setGlobalFilter}
				/>
			)}

		</div>
		// )
	);
}
export default PatientList;