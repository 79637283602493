import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from 'react-table';

import { Button, PageButton } from './paginationButtons';
import { SortIcon, SortUpIcon, SortDownIcon } from '../../assets/icons/sorting';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { update } from 'immutable';

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);




  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render('Header')}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  // console.log(preGlobalFilteredRows, "onFilterChange >>>>>")
  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // console.log(value, 'value')
  return (
    <label className="flex gap-x-2 items-baseline">
      {/* <span className="text-gray-700">Search: </span> */}
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({ maxVisiblePages, onPageChange, currentPage, pageArr, columns, data, table_id, printRef, isSearchBar = true, zoom }) {
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
    //state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      //initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: pageArr.length
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    // setPageSize(25)
  }, [])
  console.log(state);
  const [crntPage, setCurrentPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(pageArr?.length);
  const [paginationArray, setPaginationArray] = useState([]);
  console.log(paginationArray);
  const pageSize = 5;

  useEffect(() => {
    setPaginationArray(generatePaginationArray());
  }, [crntPage, totalPages]);

  // Function to handle page change API
  const handlePageChange = (newPageIndex) => {
    onPageChange(newPageIndex);
  };

  const managePageChange = (pageNumber) => {
    handlePageChange(pageNumber);
    setCurrentPage(pageNumber);
  }

  const generatePaginationArray = () => {

    //const maxVisiblePages = 5;
    const rangeOffset = Math.floor(maxVisiblePages / 2);
    let startPage = crntPage - rangeOffset;
    let endPage = crntPage + rangeOffset;

    if (startPage <= 0) {
      endPage += Math.abs(startPage) + 1;
      startPage = 1;
    }

    if (endPage > totalPages) {
      startPage -= endPage - totalPages;
      endPage = totalPages;
    }

    let pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    if (startPage > 1) {
      pages = [1, '...', ...pages];
    }
    if (endPage < totalPages) {
      pages = [...pages, '...', totalPages];
    }

    console.log("generatePaginationArray", pages);
    return pages.filter(number => number > 0) //pages;
  };


  // Render the UI for your table
  return (
    <>

      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-2 mt-5">
        {isSearchBar && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>


      {/* TABLE UI */}
      <div id={table_id} ref={printRef} className={`mt-4 flex flex-col ${zoom ? zoom : ''}`}>
        <div dir='ltr' className="-my-2 overflow-x-auto transform -mx-4 sm:-mx-6 lg:-mx-8">
          <div dir='ltr' className="py-2 align-middle inline-block tranform min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden overflow-x-auto border-b border-gray-200">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className={`${index === 8 ? "" : ""}  group px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider ${index === 10 || index === 12 || index === 13 ? "sample-processing-tr" : ""}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...column.getHeaderProps({
                            style: { width: 185 },
                          })}
                        >
                          <div className="flex items-center justify-center">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page?.length > 0 ? page.map((row, i) => {
                    // new

                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={`${index === 10 || index === 12 || index === 13 ? "" : ""} px-4 py-2 text-center  ${index === 8 ? "" : ""} ddd`}
                              // className={`px-6 py-4 text-left sample-processing-td whitespace-nowrap`}
                              role="cell"
                            >
                              {cell.column.Cell.name === 'defaultRenderer' ? (
                                <div className="text-sm font-medium text-gray-500 ">
                                  {cell.render('Cell')}
                                </div>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }) :
                    <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                      No Data Found
                    </div>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/******* Pagination *********/}
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">

        {/* Left - Previous data of data */}
        <div className="-mt-px flex w-0 flex-1">
          <button
            //onClick={() => previousPage()} disabled={!canPreviousPage}
            onClick={() => managePageChange(crntPage - 1)}
            disabled={crntPage === 1}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </button>
          <div className='inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium'>
            {preGlobalFilteredRows.length < 50 ? <span>
              Data{' '}
              <strong>
                {preGlobalFilteredRows.length} of {preGlobalFilteredRows.length}
              </strong>{' '}
            </span> :
              <span>
                Data{' '}
                <strong>
                  {state.pageSize} of {preGlobalFilteredRows.length}
                </strong>{' '}
              </span>
            }
          </div>
        </div>

        {/* Center - 1, 2, 3 */}
        <div className="hidden md:-mt-px md:flex">

          {paginationArray.length > 3 ? (
            paginationArray
              .filter((page, _, array) => {
                return (
                  page === 1 ||
                  page === array.length ||
                  page === crntPage ||
                  page === crntPage - 1 ||
                  page === crntPage + 1 ||
                  page === array.length - 1
                );
              })
              .reduce((acc, page, i, filteredArray) => {
                if (i > 0 && page > filteredArray[i - 1] + 1) {
                  acc.push('...');
                }
                acc.push(page);
                return acc;
              }, [])
              .map((page, index) => (
                <React.Fragment key={index}>
                  {typeof page === 'number' ? (
                    <button
                      onClick={() => managePageChange(page)}
                      className={`${crntPage === page ? "active inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
                    >
                      {page}
                    </button>
                  ) : (
                    <span
                      className=" inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      {page}
                    </span>
                  )}
                </React.Fragment>
              ))
          ) : (
            paginationArray.map((page, index) => (
              <button
                key={index}
                onClick={() => managePageChange(page)}
                className={`${crntPage === page ? "active inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
              >
                {page}
              </button>
            ))
          )}

        </div>

        {/* Right Side - Next Button */}
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            //onClick={() => nextPage()} disabled={!canNextPage}
            onClick={() => managePageChange(crntPage + 1)}
            disabled={crntPage === totalPages}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>

      </nav>
    </>
  );
}

export default Table;
